.toggle {
    cursor: pointer;
    display: inline-block;
    user-select: none;
  }
  
  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 40px;
    height: 21px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
  }
  .toggle-switch:before, .toggle-switch:after {
    content: "";
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 13px;
    height: 13px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: #fc2357;
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 21px;
  }
  
  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  
  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }

    
  .toggle-checkbox-barcode {
    position: absolute;
    visibility: hidden;
  }

  .toggle-checkbox-barcode:checked + .toggle-switch {
    background: #01ee50;
  }
  .toggle-checkbox-barcode:checked + .toggle-switch:before {
    left: 21px;
  }