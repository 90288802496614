body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #ffffff;
  /* background: rgb(255, 255, 255) */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content{
  width: 1200px;
  margin: 40px auto 0;
}
/* 
.btn{
  display:inline-block;
  cursor:pointer;
  text-decoration:none;
  border-radius:4px;
  border:none;
  background:0 0
}
.btn:active,.btn:focus,.btn:hover{
  text-decoration:none
}
.btn.hover-moveup{
  -webkit-transition:transform .3s ease-out;
  -webkit-transition:-webkit-transform .3s ease-out;
  transition:-webkit-transform .3s ease-out;
  transition:transform .3s ease-out;
  transition:transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.hover-moveup:hover{
  -webkit-transform:translateY(-2px);
  transform:translateY(-2px)
}
.btn.hover-movedown{
  -webkit-transition:transform .3s ease-out;
  -webkit-transition:-webkit-transform .3s ease-out;
  transition:-webkit-transform .3s ease-out;
  transition:transform .3s ease-out;
  transition:transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.hover-movedown:hover{
  -webkit-transform:translateY(2px);
  transform:translateY(2px)
}
.btn.size-small{
  padding:6px 20px;
  font-size:14px
}
.btn.size-medium{
  padding:7px 18px;
  font-size:16px
}
.btn.size-large{
  padding:12px 36px;
  font-size:18px
}
.btn.shadow{
  -webkit-box-shadow:0 3px 6px rgba(0,0,0,.1),0 3px 6px rgba(0,0,0,.1);
  box-shadow:0 3px 6px rgba(0,0,0,.1),0 3px 6px rgba(0,0,0,.1)
}
.btn.weight-light{
  font-weight:300
}
.btn.weight-normal{
  font-weight:400
}
.btn.weight-bold{
  font-weight:600
}
.btn.text-black{
  color:#363636
}
.btn.text-blue{
  color:#104acc
}
.btn.text-gray{
  color:#e3e3e3
}
.btn.text-darkgray{
  color:#8d8d8d
}
.btn.text-green{
  color:#43a047
}
.btn.text-red{
  color:#e87677
}
.btn.text-white{
  color:#fff
}
.btn.border-blue{
  border:1px solid #104acc
}
.btn.border-gray{
  border:1px solid #e3e3e3
}
.btn.border-green{
  border:1px solid #43a047
}
.btn.border-red{
  border:1px solid #e87677
}
.btn.border-white{
  border:1px solid #fff
}
.btn.border-width-thick{
  border-width:2px
}
.btn.border-width-xthick{
  border-width:3px
}
.btn.bg-blue{
  background-color:#104acc
}
.btn.bg-blue.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-blue.bg-lighten:hover{
  background-color:#114dd5
}
.btn.bg-gray{
  background-color:#e3e3e3
}
.btn.bg-gray.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-gray.bg-lighten:hover{
  background-color:#e8e8e8
}
.btn.bg-darkgray{
  background-color:#8d8d8d
}
.btn.bg-darkgray.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-darkgray.bg-lighten:hover{
  background-color:#929292
}
.btn.bg-green{
  background-color:#43a047
}
.btn.bg-green.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-green.bg-lighten:hover{
  background-color:#46a74a
}
.btn.bg-green-light{
  background-color:#48cc4d
}
.btn.bg-green-light.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-green-light.bg-lighten:hover{
  background-color:#58d05d
}
.btn.bg-navy{
  background-color:#002a8d
}
.btn.bg-navy.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-navy.bg-lighten:hover{
  background-color:#002d97
}
.btn.bg-red{
  background-color:#e87677
}
.btn.bg-red.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-red.bg-lighten:hover{
  background-color:#e97f80
}
.btn.bg-yellow{
  background-color:#fad97f
}
.btn.bg-yellow.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-yellow.bg-lighten:hover{
  background-color:#fadc89
}
.btn.bg-purple{
  background-color:#880e4f
}
.btn.bg-purple.bg-lighten{
  -webkit-transition:background-color .3s ease-out,transform .3s ease-out;
  -webkit-transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,-webkit-transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out;
  transition:background-color .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out
}
.btn.bg-purple.bg-lighten:hover{
  background-color:#910f54
}
.btn.bg-white{
  background-color:#fff
}
@media only screen and (max-width:64.0625em){
  .dropdown-f{
      background-color:#e3e3e3
  }
}
.dropdown-f ul li{
  color:#363636
}
.dropdown svg{
  width:10px;
  height:10px;
  fill:currentColor;
  display:inline-block
}
.dropdown ul{
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
  -webkit-transform:translateY(0) scale(.95,.95);
  transform:translateY(0) scale(.95,.95);
  max-height:0;
  background:#e3e3e3;
  opacity:0;
  overflow:hidden
}
@media only screen and (min-width:64.0625em){
  .dropdown ul{
      position:absolute;
      top:45px;
      right:-20px;
      -webkit-transform:translateY(-5px) scale(.95,.95);
      transform:translateY(-5px) scale(.95,.95);
      padding:8px 0;
      border-radius:2px;
      -webkit-box-shadow:0 1px 4px 0 rgba(0,8,0,.3);
      box-shadow:0 1px 4px 0 rgba(0,8,0,.3)
  }
}
.dropdown ul li{
  font-size:inherit;
  float:none;
  padding:10px
}
@media only screen and (min-width:64.0625em){
  .dropdown ul li{
      display:block;
      height:25px;
      line-height:24px;
      vertical-align:middle;
      padding:0;
      margin:3px 0
  }
  .dropdown ul li:hover{
      background:#fff
  }
}
.dropdown ul a,.dropdown ul a:hover{
  color:inherit;
  padding:0 25px 0 23px;
  border:none
}
@media only screen and (min-width:64.0625em){
  .dropdown.bottom ul::before{
      content:'';
      display:block;
      position:absolute;
      top:-7px;
      right:auto;
      bottom:auto;
      left:37px;
      border-left:7px solid transparent;
      border-right:7px solid transparent;
      border-bottom:7px solid #eaeaea;
      z-index:101
  }
  .dropdown.bottom ul::after{
      content:'';
      display:block;
      position:absolute;
      top:-6px;
      right:auto;
      bottom:auto;
      left:38px;
      border-left:6px solid transparent;
      border-right:6px solid transparent;
      border-bottom:7px solid #fff;
      z-index:102
  }
}
@media only screen and (min-width:64.0625em){
  .dropdown:focus ul,.dropdown:hover ul{
      -webkit-transform:translateY(0) scale(1,1);
      transform:translateY(0) scale(1,1);
      max-height:10000px;
      z-index:100;
      overflow:visible;
      opacity:1
  }
}
.dropdown input{
  position:fixed;
  top:-10000px;
  left:0
}
@media only screen and (max-width:64.0625em){
  .dropdown input:checked+ul{
      -webkit-transform:translateY(0) scale(1,1);
      transform:translateY(0) scale(1,1);
      opacity:1;
      max-height:10000px;
      z-index:100
  }
}
form::-webkit-input-placeholder{
  font-size:.938em
}
form:-moz-placeholder{
  font-size:.938em
}
form::-moz-placeholder{
  font-size:.938em
}
form:-ms-input-placeholder{
  font-size:.938em
}
form fieldset{
  margin:2px 0
}
form label{
  margin-bottom:7px
}
form .selectbox,form input[type=email],form input[type=number],form input[type=password],form input[type=tel],form input[type=text],form textarea{
  border:1px solid #cfcfcf;
  font-size:.938em;
  width:100%;
  padding:11px 14px 12px;
  margin-bottom:16px;
  border-radius:2px;
  background:#fff;
  -webkit-appearance:none
}
@media only screen and (min-width:64.0625em){
  form .selectbox,form input[type=email],form input[type=number],form input[type=password],form input[type=tel],form input[type=text],form textarea{
      width:100%
  }
}
form .selectbox:focus,form input[type=email]:focus,form input[type=number]:focus,form input[type=password]:focus,form input[type=tel]:focus,form input[type=text]:focus,form textarea:focus{
  border-color:#104acc
}
form input[type=submit]{
  -webkit-transition:background .3s ease;
  transition:background .3s ease
}
form textarea{
  resize:vertical
}
form .selectbox{
  position:relative;
  padding:0
}
form .selectbox:hover{
  border-color:#104acc
}
form .selectbox select{
  padding:11px 20px 12px;
  background-color:transparent;
  border:none;
  -webkit-box-shadow:none;
  box-shadow:none
}
form .selectbox select::-ms-expand{
  display:none
}
form .selectbox svg{
  position:absolute;
  top:50%;
  right:18px;
  width:11px;
  height:11px;
  margin:-4px 0 0 0;
  fill:#474747;
  z-index:10
}
meta.foundation-version{
  font-family:"/5.5.3/"
}
meta.foundation-mq-small{
  font-family:"/only screen/";
  width:0
}
meta.foundation-mq-small-only{
  font-family:"/only screen and (max-width:40em)/";
  width:0
}
meta.foundation-mq-medium{
  font-family:"/only screen and (min-width:40.0625em)/";
  width:40.0625em
}
meta.foundation-mq-medium-only{
  font-family:"/only screen and (min-width:40.0625em) and (max-width:64em)/";
  width:40.0625em
}
meta.foundation-mq-large{
  font-family:"/only screen and (min-width:64.0625em)/";
  width:40.0625em
}
meta.foundation-mq-large-only{
  font-family:"/only screen and (min-width:64.0625em) and (max-width:90em)/";
  width:64.0625em
}
meta.foundation-mq-xlarge{
  font-family:"/only screen and (min-width:90.0625em)/";
  width:90.0625em
}
meta.foundation-mq-xlarge-only{
  font-family:"/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width:90.0625em
}
meta.foundation-mq-xxlarge{
  font-family:"/only screen and (min-width:120.0625em)/";
  width:120.0625em
}
meta.foundation-data-attribute-namespace{
  font-family:false
}
body,html{
  height:100%
}
*,:after,:before{
  -webkit-box-sizing:border-box;
  box-sizing:border-box
}
body,html{
  font-size:100%
}
body{
  background:#fff;
  color:#222;
  cursor:auto;
  font-family:"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  font-style:normal;
  font-weight:200;
  line-height:1.5;
  margin:0;
  padding:0;
  position:relative
}
a:hover{
  cursor:pointer
}
img{
  max-width:100%;
  height:auto
}
img{
  -ms-interpolation-mode:bicubic
}
#map_canvas embed,#map_canvas img,#map_canvas object,.map_canvas embed,.map_canvas img,.map_canvas object,.mqa-display embed,.mqa-display img,.mqa-display object{
  max-width:none!important
}
.left{
  float:left!important
}
.right{
  float:right!important
}
.text-center{
  text-align:center
}
.text-left{
  text-align:left
}
.text-right{
  text-align:right
}
.clearfix:after,.clearfix:before{
  content:" ";
  display:table
}
.clearfix:after{
  clear:both
}
.hide{
  display:none
}
.invisible{
  visibility:hidden
}
.antialiased{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
img{
  display:inline-block;
  vertical-align:middle
}
textarea{
  height:auto;
  min-height:50px
}
select{
  width:100%
}
.row{
  margin:0 auto;
  max-width:1180px;
  width:100%
}
.row:after,.row:before{
  content:" ";
  display:table
}
.row:after{
  clear:both
}
.row.collapse>.column,.row.collapse>.columns{
  padding-left:0;
  padding-right:0
}
.row.collapse .row{
  margin-left:0;
  margin-right:0
}
.row .row{
  max-width:none;
  width:auto
}
.row .row:after,.row .row:before{
  content:" ";
  display:table
}
.row .row:after{
  clear:both
}
.row .row.collapse{
  margin:0;
  max-width:none;
  width:auto
}
.row .row.collapse:after,.row .row.collapse:before{
  content:" ";
  display:table
}
.row .row.collapse:after{
  clear:both
}
.column,.columns{
  padding-left:.9375rem;
  padding-right:.9375rem;
  width:100%;
  float:left
}
.column+.column:last-child,.column+.columns:last-child,.columns+.column:last-child,.columns+.columns:last-child{
  float:right
}
.column+.column.end,.column+.columns.end,.columns+.column.end,.columns+.columns.end{
  float:left
}
@media only screen{
  .small-push-0{
      position:relative;
      left:0;
      right:auto
  }
  .small-pull-0{
      position:relative;
      right:0;
      left:auto
  }
  .small-push-1{
      position:relative;
      left:8.33333%;
      right:auto
  }
  .small-pull-1{
      position:relative;
      right:8.33333%;
      left:auto
  }
  .small-push-2{
      position:relative;
      left:16.66667%;
      right:auto
  }
  .small-pull-2{
      position:relative;
      right:16.66667%;
      left:auto
  }
  .small-push-3{
      position:relative;
      left:25%;
      right:auto
  }
  .small-pull-3{
      position:relative;
      right:25%;
      left:auto
  }
  .small-push-4{
      position:relative;
      left:33.33333%;
      right:auto
  }
  .small-pull-4{
      position:relative;
      right:33.33333%;
      left:auto
  }
  .small-push-5{
      position:relative;
      left:41.66667%;
      right:auto
  }
  .small-pull-5{
      position:relative;
      right:41.66667%;
      left:auto
  }
  .small-push-6{
      position:relative;
      left:50%;
      right:auto
  }
  .small-pull-6{
      position:relative;
      right:50%;
      left:auto
  }
  .small-push-7{
      position:relative;
      left:58.33333%;
      right:auto
  }
  .small-pull-7{
      position:relative;
      right:58.33333%;
      left:auto
  }
  .small-push-8{
      position:relative;
      left:66.66667%;
      right:auto
  }
  .small-pull-8{
      position:relative;
      right:66.66667%;
      left:auto
  }
  .small-push-9{
      position:relative;
      left:75%;
      right:auto
  }
  .small-pull-9{
      position:relative;
      right:75%;
      left:auto
  }
  .small-push-10{
      position:relative;
      left:83.33333%;
      right:auto
  }
  .small-pull-10{
      position:relative;
      right:83.33333%;
      left:auto
  }
  .small-push-11{
      position:relative;
      left:91.66667%;
      right:auto
  }
  .small-pull-11{
      position:relative;
      right:91.66667%;
      left:auto
  }
  .column,.columns{
      position:relative;
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
  .small-1{
      width:8.33333%
  }
  .small-2{
      width:16.66667%
  }
  .small-3{
      width:25%
  }
  .small-4{
      width:33.33333%
  }
  .small-5{
      width:41.66667%
  }
  .small-6{
      width:50%
  }
  .small-7{
      width:58.33333%
  }
  .small-8{
      width:66.66667%
  }
  .small-9{
      width:75%
  }
  .small-10{
      width:83.33333%
  }
  .small-11{
      width:91.66667%
  }
  .small-12{
      width:100%
  }
  .small-offset-0{
      margin-left:0!important
  }
  .small-offset-1{
      margin-left:8.33333%!important
  }
  .small-offset-2{
      margin-left:16.66667%!important
  }
  .small-offset-3{
      margin-left:25%!important
  }
  .small-offset-4{
      margin-left:33.33333%!important
  }
  .small-offset-5{
      margin-left:41.66667%!important
  }
  .small-offset-6{
      margin-left:50%!important
  }
  .small-offset-7{
      margin-left:58.33333%!important
  }
  .small-offset-8{
      margin-left:66.66667%!important
  }
  .small-offset-9{
      margin-left:75%!important
  }
  .small-offset-10{
      margin-left:83.33333%!important
  }
  .small-offset-11{
      margin-left:91.66667%!important
  }
  .small-reset-order{
      float:left;
      left:auto;
      margin-left:0;
      margin-right:0;
      right:auto
  }
  .column.small-centered,.columns.small-centered{
      margin-left:auto;
      margin-right:auto;
      float:none
  }
  .column.small-uncentered,.columns.small-uncentered{
      float:left;
      margin-left:0;
      margin-right:0
  }
  .column.small-centered:last-child,.columns.small-centered:last-child{
      float:none
  }
  .column.small-uncentered:last-child,.columns.small-uncentered:last-child{
      float:left
  }
  .column.small-uncentered.opposite,.columns.small-uncentered.opposite{
      float:right
  }
  .row.small-collapse>.column,.row.small-collapse>.columns{
      padding-left:0;
      padding-right:0
  }
  .row.small-collapse .row{
      margin-left:0;
      margin-right:0
  }
  .row.small-uncollapse>.column,.row.small-uncollapse>.columns{
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
}
@media only screen and (min-width:40.0625em){
  .medium-push-0{
      position:relative;
      left:0;
      right:auto
  }
  .medium-pull-0{
      position:relative;
      right:0;
      left:auto
  }
  .medium-push-1{
      position:relative;
      left:8.33333%;
      right:auto
  }
  .medium-pull-1{
      position:relative;
      right:8.33333%;
      left:auto
  }
  .medium-push-2{
      position:relative;
      left:16.66667%;
      right:auto
  }
  .medium-pull-2{
      position:relative;
      right:16.66667%;
      left:auto
  }
  .medium-push-3{
      position:relative;
      left:25%;
      right:auto
  }
  .medium-pull-3{
      position:relative;
      right:25%;
      left:auto
  }
  .medium-push-4{
      position:relative;
      left:33.33333%;
      right:auto
  }
  .medium-pull-4{
      position:relative;
      right:33.33333%;
      left:auto
  }
  .medium-push-5{
      position:relative;
      left:41.66667%;
      right:auto
  }
  .medium-pull-5{
      position:relative;
      right:41.66667%;
      left:auto
  }
  .medium-push-6{
      position:relative;
      left:50%;
      right:auto
  }
  .medium-pull-6{
      position:relative;
      right:50%;
      left:auto
  }
  .medium-push-7{
      position:relative;
      left:58.33333%;
      right:auto
  }
  .medium-pull-7{
      position:relative;
      right:58.33333%;
      left:auto
  }
  .medium-push-8{
      position:relative;
      left:66.66667%;
      right:auto
  }
  .medium-pull-8{
      position:relative;
      right:66.66667%;
      left:auto
  }
  .medium-push-9{
      position:relative;
      left:75%;
      right:auto
  }
  .medium-pull-9{
      position:relative;
      right:75%;
      left:auto
  }
  .medium-push-10{
      position:relative;
      left:83.33333%;
      right:auto
  }
  .medium-pull-10{
      position:relative;
      right:83.33333%;
      left:auto
  }
  .medium-push-11{
      position:relative;
      left:91.66667%;
      right:auto
  }
  .medium-pull-11{
      position:relative;
      right:91.66667%;
      left:auto
  }
  .column,.columns{
      position:relative;
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
  .medium-1{
      width:8.33333%
  }
  .medium-2{
      width:16.66667%
  }
  .medium-3{
      width:25%
  }
  .medium-4{
      width:33.33333%
  }
  .medium-5{
      width:41.66667%
  }
  .medium-6{
      width:50%
  }
  .medium-7{
      width:58.33333%
  }
  .medium-8{
      width:66.66667%
  }
  .medium-9{
      width:75%
  }
  .medium-10{
      width:83.33333%
  }
  .medium-11{
      width:91.66667%
  }
  .medium-12{
      width:100%
  }
  .medium-offset-0{
      margin-left:0!important
  }
  .medium-offset-1{
      margin-left:8.33333%!important
  }
  .medium-offset-2{
      margin-left:16.66667%!important
  }
  .medium-offset-3{
      margin-left:25%!important
  }
  .medium-offset-4{
      margin-left:33.33333%!important
  }
  .medium-offset-5{
      margin-left:41.66667%!important
  }
  .medium-offset-6{
      margin-left:50%!important
  }
  .medium-offset-7{
      margin-left:58.33333%!important
  }
  .medium-offset-8{
      margin-left:66.66667%!important
  }
  .medium-offset-9{
      margin-left:75%!important
  }
  .medium-offset-10{
      margin-left:83.33333%!important
  }
  .medium-offset-11{
      margin-left:91.66667%!important
  }
  .medium-reset-order{
      float:left;
      left:auto;
      margin-left:0;
      margin-right:0;
      right:auto
  }
  .column.medium-centered,.columns.medium-centered{
      margin-left:auto;
      margin-right:auto;
      float:none
  }
  .column.medium-uncentered,.columns.medium-uncentered{
      float:left;
      margin-left:0;
      margin-right:0
  }
  .column.medium-centered:last-child,.columns.medium-centered:last-child{
      float:none
  }
  .column.medium-uncentered:last-child,.columns.medium-uncentered:last-child{
      float:left
  }
  .column.medium-uncentered.opposite,.columns.medium-uncentered.opposite{
      float:right
  }
  .row.medium-collapse>.column,.row.medium-collapse>.columns{
      padding-left:0;
      padding-right:0
  }
  .row.medium-collapse .row{
      margin-left:0;
      margin-right:0
  }
  .row.medium-uncollapse>.column,.row.medium-uncollapse>.columns{
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
  .push-0{
      position:relative;
      left:0;
      right:auto
  }
  .pull-0{
      position:relative;
      right:0;
      left:auto
  }
  .push-1{
      position:relative;
      left:8.33333%;
      right:auto
  }
  .pull-1{
      position:relative;
      right:8.33333%;
      left:auto
  }
  .push-2{
      position:relative;
      left:16.66667%;
      right:auto
  }
  .pull-2{
      position:relative;
      right:16.66667%;
      left:auto
  }
  .push-3{
      position:relative;
      left:25%;
      right:auto
  }
  .pull-3{
      position:relative;
      right:25%;
      left:auto
  }
  .push-4{
      position:relative;
      left:33.33333%;
      right:auto
  }
  .pull-4{
      position:relative;
      right:33.33333%;
      left:auto
  }
  .push-5{
      position:relative;
      left:41.66667%;
      right:auto
  }
  .pull-5{
      position:relative;
      right:41.66667%;
      left:auto
  }
  .push-6{
      position:relative;
      left:50%;
      right:auto
  }
  .pull-6{
      position:relative;
      right:50%;
      left:auto
  }
  .push-7{
      position:relative;
      left:58.33333%;
      right:auto
  }
  .pull-7{
      position:relative;
      right:58.33333%;
      left:auto
  }
  .push-8{
      position:relative;
      left:66.66667%;
      right:auto
  }
  .pull-8{
      position:relative;
      right:66.66667%;
      left:auto
  }
  .push-9{
      position:relative;
      left:75%;
      right:auto
  }
  .pull-9{
      position:relative;
      right:75%;
      left:auto
  }
  .push-10{
      position:relative;
      left:83.33333%;
      right:auto
  }
  .pull-10{
      position:relative;
      right:83.33333%;
      left:auto
  }
  .push-11{
      position:relative;
      left:91.66667%;
      right:auto
  }
  .pull-11{
      position:relative;
      right:91.66667%;
      left:auto
  }
}
@media only screen and (min-width:64.0625em){
  .large-push-0{
      position:relative;
      left:0;
      right:auto
  }
  .large-pull-0{
      position:relative;
      right:0;
      left:auto
  }
  .large-push-1{
      position:relative;
      left:8.33333%;
      right:auto
  }
  .large-pull-1{
      position:relative;
      right:8.33333%;
      left:auto
  }
  .large-push-2{
      position:relative;
      left:16.66667%;
      right:auto
  }
  .large-pull-2{
      position:relative;
      right:16.66667%;
      left:auto
  }
  .large-push-3{
      position:relative;
      left:25%;
      right:auto
  }
  .large-pull-3{
      position:relative;
      right:25%;
      left:auto
  }
  .large-push-4{
      position:relative;
      left:33.33333%;
      right:auto
  }
  .large-pull-4{
      position:relative;
      right:33.33333%;
      left:auto
  }
  .large-push-5{
      position:relative;
      left:41.66667%;
      right:auto
  }
  .large-pull-5{
      position:relative;
      right:41.66667%;
      left:auto
  }
  .large-push-6{
      position:relative;
      left:50%;
      right:auto
  }
  .large-pull-6{
      position:relative;
      right:50%;
      left:auto
  }
  .large-push-7{
      position:relative;
      left:58.33333%;
      right:auto
  }
  .large-pull-7{
      position:relative;
      right:58.33333%;
      left:auto
  }
  .large-push-8{
      position:relative;
      left:66.66667%;
      right:auto
  }
  .large-pull-8{
      position:relative;
      right:66.66667%;
      left:auto
  }
  .large-push-9{
      position:relative;
      left:75%;
      right:auto
  }
  .large-pull-9{
      position:relative;
      right:75%;
      left:auto
  }
  .large-push-10{
      position:relative;
      left:83.33333%;
      right:auto
  }
  .large-pull-10{
      position:relative;
      right:83.33333%;
      left:auto
  }
  .large-push-11{
      position:relative;
      left:91.66667%;
      right:auto
  }
  .large-pull-11{
      position:relative;
      right:91.66667%;
      left:auto
  }
  .column,.columns{
      position:relative;
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
  .large-1{
      width:8.33333%
  }
  .large-2{
      width:16.66667%
  }
  .large-3{
      width:25%
  }
  .large-4{
      width:33.33333%
  }
  .large-5{
      width:41.66667%
  }
  .large-6{
      width:50%
  }
  .large-7{
      width:58.33333%
  }
  .large-8{
      width:66.66667%
  }
  .large-9{
      width:75%
  }
  .large-10{
      width:83.33333%
  }
  .large-11{
      width:91.66667%
  }
  .large-12{
      width:100%
  }
  .large-offset-0{
      margin-left:0!important
  }
  .large-offset-1{
      margin-left:8.33333%!important
  }
  .large-offset-2{
      margin-left:16.66667%!important
  }
  .large-offset-3{
      margin-left:25%!important
  }
  .large-offset-4{
      margin-left:33.33333%!important
  }
  .large-offset-5{
      margin-left:41.66667%!important
  }
  .large-offset-6{
      margin-left:50%!important
  }
  .large-offset-7{
      margin-left:58.33333%!important
  }
  .large-offset-8{
      margin-left:66.66667%!important
  }
  .large-offset-9{
      margin-left:75%!important
  }
  .large-offset-10{
      margin-left:83.33333%!important
  }
  .large-offset-11{
      margin-left:91.66667%!important
  }
  .large-reset-order{
      float:left;
      left:auto;
      margin-left:0;
      margin-right:0;
      right:auto
  }
  .column.large-centered,.columns.large-centered{
      margin-left:auto;
      margin-right:auto;
      float:none
  }
  .column.large-uncentered,.columns.large-uncentered{
      float:left;
      margin-left:0;
      margin-right:0
  }
  .column.large-centered:last-child,.columns.large-centered:last-child{
      float:none
  }
  .column.large-uncentered:last-child,.columns.large-uncentered:last-child{
      float:left
  }
  .column.large-uncentered.opposite,.columns.large-uncentered.opposite{
      float:right
  }
  .row.large-collapse>.column,.row.large-collapse>.columns{
      padding-left:0;
      padding-right:0
  }
  .row.large-collapse .row{
      margin-left:0;
      margin-right:0
  }
  .row.large-uncollapse>.column,.row.large-uncollapse>.columns{
      padding-left:.9375rem;
      padding-right:.9375rem;
      float:left
  }
  .push-0{
      position:relative;
      left:0;
      right:auto
  }
  .pull-0{
      position:relative;
      right:0;
      left:auto
  }
  .push-1{
      position:relative;
      left:8.33333%;
      right:auto
  }
  .pull-1{
      position:relative;
      right:8.33333%;
      left:auto
  }
  .push-2{
      position:relative;
      left:16.66667%;
      right:auto
  }
  .pull-2{
      position:relative;
      right:16.66667%;
      left:auto
  }
  .push-3{
      position:relative;
      left:25%;
      right:auto
  }
  .pull-3{
      position:relative;
      right:25%;
      left:auto
  }
  .push-4{
      position:relative;
      left:33.33333%;
      right:auto
  }
  .pull-4{
      position:relative;
      right:33.33333%;
      left:auto
  }
  .push-5{
      position:relative;
      left:41.66667%;
      right:auto
  }
  .pull-5{
      position:relative;
      right:41.66667%;
      left:auto
  }
  .push-6{
      position:relative;
      left:50%;
      right:auto
  }
  .pull-6{
      position:relative;
      right:50%;
      left:auto
  }
  .push-7{
      position:relative;
      left:58.33333%;
      right:auto
  }
  .pull-7{
      position:relative;
      right:58.33333%;
      left:auto
  }
  .push-8{
      position:relative;
      left:66.66667%;
      right:auto
  }
  .pull-8{
      position:relative;
      right:66.66667%;
      left:auto
  }
  .push-9{
      position:relative;
      left:75%;
      right:auto
  }
  .pull-9{
      position:relative;
      right:75%;
      left:auto
  }
  .push-10{
      position:relative;
      left:83.33333%;
      right:auto
  }
  .pull-10{
      position:relative;
      right:83.33333%;
      left:auto
  }
  .push-11{
      position:relative;
      left:91.66667%;
      right:auto
  }
  .pull-11{
      position:relative;
      right:91.66667%;
      left:auto
  }
}
[class*=block-grid-]{
  display:block;
  padding:0;
  margin:0 -.625rem
}
[class*=block-grid-]:after,[class*=block-grid-]:before{
  content:" ";
  display:table
}
[class*=block-grid-]:after{
  clear:both
}
[class*=block-grid-]>li{
  display:block;
  float:left;
  height:auto;
  padding:0 .625rem 1.25rem
}
@media only screen{
  .small-block-grid-1>li{
      list-style:none;
      width:100%
  }
  .small-block-grid-1>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-1>li:nth-of-type(1n+1){
      clear:both
  }
  .small-block-grid-2>li{
      list-style:none;
      width:50%
  }
  .small-block-grid-2>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-2>li:nth-of-type(2n+1){
      clear:both
  }
  .small-block-grid-3>li{
      list-style:none;
      width:33.33333%
  }
  .small-block-grid-3>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-3>li:nth-of-type(3n+1){
      clear:both
  }
  .small-block-grid-4>li{
      list-style:none;
      width:25%
  }
  .small-block-grid-4>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-4>li:nth-of-type(4n+1){
      clear:both
  }
  .small-block-grid-5>li{
      list-style:none;
      width:20%
  }
  .small-block-grid-5>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-5>li:nth-of-type(5n+1){
      clear:both
  }
  .small-block-grid-6>li{
      list-style:none;
      width:16.66667%
  }
  .small-block-grid-6>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-6>li:nth-of-type(6n+1){
      clear:both
  }
  .small-block-grid-7>li{
      list-style:none;
      width:14.28571%
  }
  .small-block-grid-7>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-7>li:nth-of-type(7n+1){
      clear:both
  }
  .small-block-grid-8>li{
      list-style:none;
      width:12.5%
  }
  .small-block-grid-8>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-8>li:nth-of-type(8n+1){
      clear:both
  }
  .small-block-grid-9>li{
      list-style:none;
      width:11.11111%
  }
  .small-block-grid-9>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-9>li:nth-of-type(9n+1){
      clear:both
  }
  .small-block-grid-10>li{
      list-style:none;
      width:10%
  }
  .small-block-grid-10>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-10>li:nth-of-type(10n+1){
      clear:both
  }
  .small-block-grid-11>li{
      list-style:none;
      width:9.09091%
  }
  .small-block-grid-11>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-11>li:nth-of-type(11n+1){
      clear:both
  }
  .small-block-grid-12>li{
      list-style:none;
      width:8.33333%
  }
  .small-block-grid-12>li:nth-of-type(1n){
      clear:none
  }
  .small-block-grid-12>li:nth-of-type(12n+1){
      clear:both
  }
}
@media only screen and (min-width:40.0625em){
  .medium-block-grid-1>li{
      list-style:none;
      width:100%
  }
  .medium-block-grid-1>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-1>li:nth-of-type(1n+1){
      clear:both
  }
  .medium-block-grid-2>li{
      list-style:none;
      width:50%
  }
  .medium-block-grid-2>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-2>li:nth-of-type(2n+1){
      clear:both
  }
  .medium-block-grid-3>li{
      list-style:none;
      width:33.33333%
  }
  .medium-block-grid-3>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-3>li:nth-of-type(3n+1){
      clear:both
  }
  .medium-block-grid-4>li{
      list-style:none;
      width:25%
  }
  .medium-block-grid-4>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-4>li:nth-of-type(4n+1){
      clear:both
  }
  .medium-block-grid-5>li{
      list-style:none;
      width:20%
  }
  .medium-block-grid-5>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-5>li:nth-of-type(5n+1){
      clear:both
  }
  .medium-block-grid-6>li{
      list-style:none;
      width:16.66667%
  }
  .medium-block-grid-6>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-6>li:nth-of-type(6n+1){
      clear:both
  }
  .medium-block-grid-7>li{
      list-style:none;
      width:14.28571%
  }
  .medium-block-grid-7>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-7>li:nth-of-type(7n+1){
      clear:both
  }
  .medium-block-grid-8>li{
      list-style:none;
      width:12.5%
  }
  .medium-block-grid-8>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-8>li:nth-of-type(8n+1){
      clear:both
  }
  .medium-block-grid-9>li{
      list-style:none;
      width:11.11111%
  }
  .medium-block-grid-9>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-9>li:nth-of-type(9n+1){
      clear:both
  }
  .medium-block-grid-10>li{
      list-style:none;
      width:10%
  }
  .medium-block-grid-10>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-10>li:nth-of-type(10n+1){
      clear:both
  }
  .medium-block-grid-11>li{
      list-style:none;
      width:9.09091%
  }
  .medium-block-grid-11>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-11>li:nth-of-type(11n+1){
      clear:both
  }
  .medium-block-grid-12>li{
      list-style:none;
      width:8.33333%
  }
  .medium-block-grid-12>li:nth-of-type(1n){
      clear:none
  }
  .medium-block-grid-12>li:nth-of-type(12n+1){
      clear:both
  }
}
@media only screen and (min-width:64.0625em){
  .large-block-grid-1>li{
      list-style:none;
      width:100%
  }
  .large-block-grid-1>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-1>li:nth-of-type(1n+1){
      clear:both
  }
  .large-block-grid-2>li{
      list-style:none;
      width:50%
  }
  .large-block-grid-2>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-2>li:nth-of-type(2n+1){
      clear:both
  }
  .large-block-grid-3>li{
      list-style:none;
      width:33.33333%
  }
  .large-block-grid-3>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-3>li:nth-of-type(3n+1){
      clear:both
  }
  .large-block-grid-4>li{
      list-style:none;
      width:25%
  }
  .large-block-grid-4>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-4>li:nth-of-type(4n+1){
      clear:both
  }
  .large-block-grid-5>li{
      list-style:none;
      width:20%
  }
  .large-block-grid-5>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-5>li:nth-of-type(5n+1){
      clear:both
  }
  .large-block-grid-6>li{
      list-style:none;
      width:16.66667%
  }
  .large-block-grid-6>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-6>li:nth-of-type(6n+1){
      clear:both
  }
  .large-block-grid-7>li{
      list-style:none;
      width:14.28571%
  }
  .large-block-grid-7>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-7>li:nth-of-type(7n+1){
      clear:both
  }
  .large-block-grid-8>li{
      list-style:none;
      width:12.5%
  }
  .large-block-grid-8>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-8>li:nth-of-type(8n+1){
      clear:both
  }
  .large-block-grid-9>li{
      list-style:none;
      width:11.11111%
  }
  .large-block-grid-9>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-9>li:nth-of-type(9n+1){
      clear:both
  }
  .large-block-grid-10>li{
      list-style:none;
      width:10%
  }
  .large-block-grid-10>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-10>li:nth-of-type(10n+1){
      clear:both
  }
  .large-block-grid-11>li{
      list-style:none;
      width:9.09091%
  }
  .large-block-grid-11>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-11>li:nth-of-type(11n+1){
      clear:both
  }
  .large-block-grid-12>li{
      list-style:none;
      width:8.33333%
  }
  .large-block-grid-12>li:nth-of-type(1n){
      clear:none
  }
  .large-block-grid-12>li:nth-of-type(12n+1){
      clear:both
  }
}
.handsontable *{
  font-size:inherit
}
.handsontable .at-risk-checked{
  background-color:#ffecec
}
.headline{
  position:relative
}
.headline .red{
  color:#e87677
}
.headline.centered{
  text-align:center;
  padding:0
}
.headline.centered span{
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  font-weight:400;
  text-align:center;
  position:absolute;
  top:50%;
  left:50%;
  padding:0 6px;
  background:#fff;
  width:85%
}
@media only screen and (min-width:64.0625em){
  .headline.centered span{
      padding:0 0 50px 0;
      width:auto
  }
}
[aria-role=menubox]{
  height:42px;
  line-height:41px;
  vertical-align:middle;
  border:1px solid #f7f7f7;
  position:relative;
  font-size:.938em;
  min-height:42px;
  padding:0 27px;
  background:#edeef0
}
[aria-role=menubox] select{
  border:none;
  background:0 0
}
[aria-role=menubox] svg{
  position:absolute;
  top:50%;
  right:18px;
  width:11px;
  height:11px;
  margin:-4px 0 0 0;
  fill:#363636;
  z-index:10
}
[aria-role=menubox].large,[aria-role=menubox].small{
  text-transform:uppercase
}
[aria-role=menubox].large{
  height:50px;
  line-height:49px;
  vertical-align:middle;
  font-size:.938em;
  padding:0 33px
}
[aria-role=menubox].small{
  height:38px;
  line-height:37px;
  vertical-align:middle;
  font-size:.813em;
  padding:0 28px
}
body.modal-open{
  overflow:hidden
}
body .fancybox-slide--iframe .fancybox-content{
  width:100%;
  height:70%;
  max-width:calc(100% - 50px);
  max-height:calc(100% - 44px);
  padding:0;
  overflow:visible;
  background:#fff
}
@media only screen and (min-width:64.0625em){
  body .fancybox-slide--iframe .fancybox-content{
      width:80%;
      height:80%;
      max-width:calc(100% - 100px);
      max-height:calc(100% - 88px)
  }
}
.modal .modal-container{
  position:fixed;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  width:480px;
  height:440px;
  padding:40px 0 0;
  z-index:400;
  border-radius:10px;
  overflow:hidden;
  -webkit-box-shadow:0 3px 26px rgba(0,0,0,.1),0 3px 26px rgba(0,0,0,.1);
  box-shadow:0 3px 26px rgba(0,0,0,.1),0 3px 26px rgba(0,0,0,.1);
  background-color:#fff
}
.modal .modal-container .modal-footer,.modal .modal-container .modal-header{
  padding:24px 10px 18px
}
.modal .modal-container .modal-header a svg{
  width:12px;
  height:12px;
  -webkit-transition:fill .2s ease-in-out;
  transition:fill .2s ease-in-out;
  fill:#8d8d8d
}
.modal .modal-container .modal-header a:hover svg{
  fill:#363636
}
.modal .modal-container .modal-content{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden
}
.modal .modal-container .modal-footer a{
  margin-left:10px
}
.modal .modal-backdrop{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:300;
  overflow:hidden
}
.modal .modal-backdrop.bg-light{
  background:#fff
}
.modal .modal-backdrop.bg-light-transparent{
  background:rgba(255,255,255,.9)
}
.modal .modal-backdrop.bg-dark{
  background:#000
}
.modal .modal-backdrop.bg-dark-transparent{
  background:rgba(0,0,0,.9)
}
.modal .hidden,.modal.hidden{
  display:none
}
html{
  font-family:sans-serif;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:transparent;
  -webkit-touch-callout:none;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
:focus{
  outline:0
}
body{
  margin:0
}
article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{
  display:block
}
audio,canvas,progress,video{
  display:inline-block;
  vertical-align:baseline
}
progress{
  width:100px
}
audio:not([controls]){
  display:none;
  height:0
}
[hidden],template{
  display:none
}
a{
  background-color:transparent
}
a:active,a:hover{
  outline:0
}
abbr[title]{
  border-bottom:1px dotted
}
b,strong{
  font-weight:700
}
dfn{
  font-style:italic
}
h1{
  font-size:2em;
  margin:.67em 0
}
mark{
  background:#ff0;
  color:#000
}
small{
  font-size:80%
}
sub,sup{
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}
sup{
  top:-.5em
}
sub{
  bottom:-.25em
}
img{
  border:0
}
svg:not(:root){
  overflow:hidden
}
figure{
  margin:1em 40px
}
hr{
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
  height:0
}
pre{
  overflow:auto
}
code,kbd,pre,samp{
  font-family:monospace,monospace;
  font-size:1em
}
code{
  font-size:14px!important
}
button,input,optgroup,select,textarea{
  color:inherit;
  font:inherit;
  margin:0
}
button{
  overflow:visible
}
button,select{
  text-transform:none
}
button,html input[type=button],input[type=reset],input[type=submit]{
  -webkit-appearance:button;
  cursor:pointer
}
button[disabled],html input[disabled]{
  cursor:default
}
button::-moz-focus-inner,input::-moz-focus-inner{
  border:0;
  padding:0
}
input{
  line-height:normal
}
input[type=checkbox],input[type=radio]{
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:0
}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{
  height:auto
}
input[type=search]{
  -webkit-appearance:textfield;
  -webkit-box-sizing:content-box;
  box-sizing:content-box
}
input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{
  -webkit-appearance:none
}
fieldset,legend{
  border:0;
  padding:0;
  margin:0
}
textarea{
  overflow:auto
}
optgroup{
  font-weight:700
}
table{
  border-collapse:collapse;
  border-spacing:0
}
td,th{
  padding:0
}
li,ul{
  padding:0;
  margin:0;
  list-style:none
}
select{
  -webkit-appearance:none;
  -moz-appearance:none
}
.token{
  font-size:13px
}
.notification{
  font-size:15px;
  padding:15px;
  margin:20px 0;
  color:#fff
}
.notification.error{
  background:#e87677
}
.notification.info{
  background:#104acc
}
.notification.success{
  background:#43a047
}
.notification.warning{
  background:#fad97f
}
.tooltip-btn{
  position:relative;
  top:-2px;
  left:4px;
  width:15px;
  height:15px;
  display:inline-block;
  color:#cfcfcf;
  line-height:1.2;
  text-align:center;
  cursor:pointer;
  font-size:11px;
  border-radius:100%;
  border:1px solid #cfcfcf
}
.tooltip-btn:hover{
  background-color:#104acc;
  color:#f7f7f7;
  border:1px solid transparent
}
.tooltip-inner{
  position:absolute;
  top:-25px;
  left:25px;
  width:auto;
  height:auto;
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  font-size:13px;
  line-height:1.5;
  text-align:left;
  padding:8px 15px;
  cursor:default;
  color:#f7f7f7;
  background-color:#104acc;
  border:1px solid #f7f7f7;
  border-radius:4px;
  visibility:hidden;
  opacity:0;
  z-index:10;
  -webkit-box-shadow:0 0 14px 0 rgba(160,160,160,.2);
  box-shadow:0 0 14px 0 rgba(160,160,160,.2)
}
.tooltip-inner ul{
  list-style:square
}
.tooltip-inner ul li{
  list-style:square;
  font-size:inherit
}
.tooltip-btn:hover .tooltip-inner{
  visibility:visible;
  opacity:1
}
.tooltip-angle,.tooltip-angle-inner{
  position:absolute;
  height:0;
  width:0;
  line-height:0
}
.tooltip-top .tooltip-inner{
  top:auto;
  bottom:100%;
  margin-bottom:10px
}
.tooltip-top .tooltip-angle{
  top:100%;
  left:90px;
  border-width:7px 6px 0 6px;
  border-style:solid;
  border-color:#104acc transparent
}
.tooltip-top .tooltip-angle-inner{
  top:-8px;
  left:-6px;
  border-width:7px 6px 0 6px;
  border-style:solid;
  border-color:#fff transparent
}
.tooltip-right .tooltip-angle{
  top:25px;
  left:-6px;
  border-width:5px 6px 7px 0;
  border-style:solid;
  border-color:transparent #104acc
}
.tooltip-right .tooltip-angle-inner{
  top:-5px;
  left:1px;
  border-width:5px 6px 7px 0;
  border-style:solid;
  border-color:transparent #fff
}
.top-announcement{
  position:relative;
  top:-18px;
  left:0;
  padding:9px 0 8px;
  display:none;
  color:#fff;
  font-size:14px;
  background-color:#000
}
@media only screen and (min-width:64.0625em){
  .top-announcement{
      display:block
  }
}
.top-announcement a,.top-announcement span{
  font-size:1em
}
.top-announcement span{
  margin-left:5px;
  padding:3px 8px 4px;
  font-weight:700;
  color:#fff;
  letter-spacing:1px;
  border-radius:8px;
  background-color:#e15354
}
.top-announcement a,.top-announcement a:active,.top-announcement a:hover,.top-announcement a:visited{
  color:#fff;
  font-weight:600
}
.wf-loading h1,.wf-loading h2,.wf-loading h3,.wf-loading h4,.wf-loading h5,.wf-loading h6{
  font-family:Roboto,Helvetica,Arial,sans-serif
}
.wf-loading .blog h3,.wf-loading .post .headline,.wf-loading .wait-for-font{
  visibility:hidden
}
body,html{
  font-family:Roboto,Helvetica,Arial,sans-serif;
  font-weight:400;
  color:#363636
}
a,label,ol li,p,span,ul li{
  font-size:16px;
  line-height:1.6
}
h1,h2,h3,h4,h5{
  line-height:1.2;
  font-weight:300;
  font-family:Roboto,Helvetica,Arial,sans-serif;
  font-size:15px;
  letter-spacing:-.3px
}
h1.weight-light,h2.weight-light,h3.weight-light,h4.weight-light,h5.weight-light{
  font-weight:300
}
h1.weight-normal,h2.weight-normal,h3.weight-normal,h4.weight-normal,h5.weight-normal{
  font-weight:400
}
h1.weight-bold,h2.weight-bold,h3.weight-bold,h4.weight-bold,h5.weight-bold{
  font-weight:600
}
h1.text-black,h2.text-black,h3.text-black,h4.text-black,h5.text-black{
  color:#363636
}
h1.text-blue,h2.text-blue,h3.text-blue,h4.text-blue,h5.text-blue{
  color:#104acc
}
h1.text-gray,h2.text-gray,h3.text-gray,h4.text-gray,h5.text-gray{
  color:#e3e3e3
}
h1.text-green,h2.text-green,h3.text-green,h4.text-green,h5.text-green{
  color:#43a047
}
h1.text-red,h2.text-red,h3.text-red,h4.text-red,h5.text-red{
  color:#e87677
}
h1.text-white,h2.text-white,h3.text-white,h4.text-white,h5.text-white{
  color:#fff
}
h1.size-small,h2.size-small,h3.size-small,h4.size-small,h5.size-small{
  font-size:16px
}
h1.size-medium,h2.size-medium,h3.size-medium,h4.size-medium,h5.size-medium{
  font-size:24px
}
h1.size-large,h2.size-large,h3.size-large,h4.size-large,h5.size-large{
  font-size:30px
}
h1>span,h2>span,h3>span,h4>span,h5>span{
  display:inline-block;
  font-size:18px;
  font-weight:400;
  line-height:1.5
}
h1>span a,h2>span a,h3>span a,h4>span a,h5>span a{
  font-size:inherit
}
h1>hr,h2>hr,h3>hr,h4>hr,h5>hr{
  width:38px;
  padding:0;
  margin:0;
  position:relative;
  top:-16px;
  border:2px solid #104acc
}
h1>hr.centered,h2>hr.centered,h3>hr.centered,h4>hr.centered,h5>hr.centered{
  left:50%;
  margin-left:-19px
}
a,a:active,a:hover,a:visited{
  text-decoration:none;
  color:#104acc
}
a:hover{
  color:#0d3ca6
}
code{
  border:1px solid #f7f7f7;
  font-family:Consolas,"Liberation Mono",Courier,monospace;
  font-size:12px;
  word-break:break-all;
  display:block;
  line-height:1.6;
  text-align:left;
  padding:14px 18px;
  margin:16px 0 27px;
  background:#f7f7f7
}
code.inline{
  display:inline;
  padding:0 2px
}
.table-container{
  width:100%;
  overflow-y:auto
}
@media only screen and (min-width:64.0625em){
  .table-container{
      overflow:inherit
  }
}
.table-container table{
  font-size:15px;
  text-align:center;
  width:100%
}
.table-container table tr:nth-child(even){
  background-color:#f7f7f7
}
.table-container table th{
  font-weight:500
}
.table-container table td,.table-container table th{
  white-space:nowrap;
  padding:20px 20px
}
.table-container table.striped tbody tr:nth-child(odd){
  background:#f7f7f7
}
.table-container table a,.table-container table a:focus,.table-container table a:hover{
  color:#104acc;
  font-size:1em;
  text-align:center
}
.stripe-black{
  background-color:#000
}
.stripe-blue{
  background-color:#104acc
}
.stripe-gray{
  background-color:#f5f6fa
}
.stripe-green{
  background-color:#43a047
}
.stripe-red{
  background-color:#e87677
}
.stripe-white{
  background-color:#fff
}
.tabs .tab{
  width:100%;
  overflow:auto;
  white-space:nowrap
}
.tabs .tab ul li{
  padding:14px 20px 12px;
  display:inline-block;
  border-bottom:4px solid transparent;
  outline:0;
  cursor:pointer;
  font-size:16px;
  background-color:inherit
}
.tabs .tab ul li:hover{
  color:#8d8d8d
}
.tabs .tab ul li.active{
  cursor:default;
  border-bottom:3px solid #104acc
}
.tabs .tab ul li.active:hover{
  color:inherit
}
.tabs .tab ul li>*{
  pointer-events:none
}
.tabs .tab ul li svg{
  width:20px;
  height:20px;
  position:relative;
  top:5px;
  left:-6px
}
.tabs>div:not(.tab){
  padding:14px 20px 16px;
  background-color:#f5f6fa
}
.tabs>div{
  display:none
}
.tabs>div.active,.tabs>div:first-child{
  display:block
}
.user .headline{
  margin-bottom:0
}
.user .user-menu{
  border-bottom:1px solid #f7f7f7;
  margin-top:4px
}
@media only screen and (min-width:64.0625em){
  .user .user-menu{
      margin-top:0
  }
}
.user .user-menu label{
  font-size:15px;
  position:relative;
  display:block;
  padding-bottom:7px
}
.user .user-menu label svg{
  content:'';
  display:block;
  position:absolute;
  top:6px;
  right:17px;
  bottom:auto;
  left:auto;
  width:14px;
  height:14px;
  fill:currentColor
}
.user .user-menu input{
  display:none
}
.user .user-menu input:checked+nav{
  display:block
}
@media only screen and (min-width:64.0625em){
  .user .user-menu input,.user .user-menu label{
      display:none
  }
}
.user .user-menu nav{
  display:none
}
.user .user-menu nav a{
  border-top:1px solid #f7f7f7;
  font-size:15px;
  float:none;
  width:100%;
  display:inline-block;
  padding:6px 10px
}
.user .user-menu nav a.active{
  color:inherit;
  cursor:default;
  text-decoration:none
}
.user .user-menu nav svg{
  width:12px;
  height:12px;
  position:relative;
  top:1px;
  margin-left:4px;
  fill:currentColor
}
@media only screen and (min-width:64.0625em){
  .user .user-menu nav{
      display:block;
      padding:17px 0
  }
  .user .user-menu nav a{
      width:auto;
      padding:0;
      margin:0 15px 0 18px;
      border:none
  }
}
.star-counter{
  display:none
}
@media only screen and (min-width:64.0625em){
  .star-counter{
      position:absolute;
      top:-2px;
      left:150px;
      display:block
  }
  .star-counter .github-star{
      -webkit-transition:opacity .2s ease-out;
      transition:opacity .2s ease-out;
      position:relative;
      top:-6px;
      left:8px;
      padding:1px 5px;
      display:inline-block;
      color:#363636;
      cursor:pointer;
      font-size:11px;
      font-weight:600;
      border-radius:8px;
      border:2px solid #363636
  }
  .star-counter .github-star:hover{
      opacity:.7
  }
  .star-counter .github-star .triangle{
      border-top:4px solid transparent;
      border-bottom:4px solid transparent;
      border-right:4px solid #363636;
      position:absolute;
      top:5px;
      left:-6px
  }
  .star-counter svg{
      width:25px;
      height:25px;
      fill:#363636;
      margin-bottom:-5px
  }
}
@media only screen and (min-width:64.0625em) and (min-width:40.0625em){
  .star-counter svg{
      width:18px;
      height:18px;
      float:left
  }
}
.brands img{
  -webkit-transform:translateY(-25%);
  transform:translateY(-25%);
  -webkit-filter:grayscale(100%);
  -moz-filter:grayscale(100%);
  -ms-filter:grayscale(100%);
  -o-filter:grayscale(100%);
  filter:grayscale(100%);
  margin:0 0 60px;
  max-width:110px;
  max-height:70px;
  opacity:.75
}
@media only screen and (min-width:64.0625em){
  .brands img{
      margin:0;
      padding:11px;
      max-height:52px
  }
}
@media screen and (-ms-high-contrast:none),(-ms-high-contrast:none){
  .brands:nth-child(1) img{
      width:115px;
      height:49px
  }
  .brands:nth-child(2) img{
      width:115px;
      height:43px
  }
  .brands:nth-child(3) img{
      width:115px;
      height:27px
  }
  .brands:nth-child(4) img{
      width:87px;
      height:66px
  }
  .brands:nth-child(5) img{
      width:115px;
      height:21px
  }
  .brands:nth-child(6) img{
      width:55px;
      height:66px
  }
}
footer{
  margin-top:80px;
  padding:60px 0 80px
}
footer a,footer a:active,footer a:visited,footer h4,footer p{
  font-size:14px;
  color:#363636
}
footer a:hover{
  color:#8d8d8d
}
footer h4{
  margin-top:40px
}
@media only screen and (min-width:64.0625em){
  footer h4{
      margin-top:24px
  }
}
footer .row>div:first-child a,footer .row>div:first-child a:active,footer .row>div:first-child a:hover,footer .row>div:first-child a:visited,footer .row>div:first-child p{
  font-size:13px
}
footer svg{
  width:150px;
  height:30px;
  position:inherit;
  margin-top:20px;
  fill:#000
}
header{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  padding:18px 0 0;
  z-index:200
}
header .logo{
  position:relative;
  top:13px;
  left:0
}
@media only screen and (min-width:64.0625em){
  header .logo{
      position:relative;
      top:10px;
      left:0
  }
}
header .logo svg.handsontable-logo{
  width:130px;
  height:20px;
  fill:#363636
}
header>.row>.columns:last-child{
  position:static
}
header nav>a{
  position:absolute;
  top:4px;
  right:5px;
  padding:20px;
  display:block;
  z-index:11
}
@media only screen and (min-width:64.0625em){
  header nav>a{
      display:none
  }
}
header nav>a svg{
  width:28px;
  height:28px;
  fill:#363636
}
header nav ul{
  display:none;
  font-size:0
}
@media only screen and (min-width:64.0625em){
  header nav ul{
      display:block
  }
}
header nav ul li{
  padding:0 17px;
  display:inline-block
}
header nav ul li:last-child{
  padding-right:0
}
header nav ul li.mobile-only{
  display:none
}
header nav ul li.news{
  padding-right:32px;
  position:relative
}
header nav ul li.news svg{
  width:18px;
  height:18px;
  position:relative;
  top:4px;
  left:0;
  stroke:#363636
}
header nav ul li.news #HW_badge_cont{
  position:absolute;
  top:0;
  left:13px
}
header nav ul li.news #HW_badge_cont #HW_badge{
  width:12px;
  height:12px;
  position:relative;
  top:0;
  left:0;
  line-height:13px;
  background-color:#e25354
}
header nav ul li.news #HW_badge_cont #HW_badge.HW_softHidden{
  opacity:.4;
  background-color:#48cc4d
}
header nav ul li a,header nav ul li a:active,header nav ul li a:hover,header nav ul li a:visited{
  font-size:15px;
  color:#363636
}
header nav ul li a:hover{
  color:#8d8d8d
}
@media only screen and (min-width:64.0625em){
  header nav.mobile-active{
      display:none
  }
}
header nav.mobile-active>a svg{
  fill:#104acc
}
header nav.mobile-active ul{
  position:absolute;
  top:12px;
  left:2%;
  width:96%;
  padding:30px 0 8px;
  display:block;
  text-align:center;
  border-radius:4px;
  z-index:10;
  -webkit-box-shadow:0 3px 18px rgba(0,0,0,.15),0 3px 18px rgba(0,0,0,.15);
  box-shadow:0 3px 18px rgba(0,0,0,.15),0 3px 18px rgba(0,0,0,.15);
  background:#fff
}
header nav.mobile-active ul li{
  width:100%;
  padding:10px 0
}
header nav.mobile-active ul li.mobile-only{
  display:inline-block
}
header nav.mobile-active ul li a,header nav.mobile-active ul li a:active,header nav.mobile-active ul li a:hover,header nav.mobile-active ul li a:visited{
  font-size:18px!important;
  color:#104acc!important;
  background-color:transparent!important
}
header nav.mobile-active ul li.news{
  display:none
}
header nav.mobile-inactive .btn{
  white-space:nowrap
}
header.header-white .logo .github-star{
  border:2px solid #f7f7f7;
  color:#f7f7f7
}
header.header-white .logo .github-star .triangle{
  border-right-color:#f7f7f7
}
header.header-white .logo svg{
  fill:#fff!important
}
header.header-white a:active:not(.btn),header.header-white a:hover:not(.btn),header.header-white a:not(.btn),header.header-white a:visited:not(.btn){
  color:#104acc
}
@media only screen and (min-width:64.0625em){
  header.header-white a:active:not(.btn),header.header-white a:hover:not(.btn),header.header-white a:not(.btn),header.header-white a:visited:not(.btn){
      color:#fff
  }
}
@media only screen and (min-width:64.0625em){
  header.header-white a:hover:not(.btn){
      color:#e7f3ff
  }
}
header.header-white svg{
  fill:#fff
}
header.header-white .news svg{
  stroke:#fff
}
header.header-white .btn{
  white-space:nowrap;
  color:#104acc;
  opacity:1;
  background-color:#fff
}
header.header-white .btn:hover{
  -webkit-transition:all .3s ease;
  transition:all .3s ease;
  opacity:.9;
  color:#104acc!important;
  background-color:#fff!important
}
@media only screen and (max-width:40.0625em){
  #HW_frame_cont{
      display:none
  }
}
[aria-hidden=true]:not(#HW_frame){
  display:none
}
.gap-top-xxsmall{
  padding-top:20px
}
.gap-top-xsmall{
  padding-top:40px
}
.gap-top-small{
  padding-top:70px
}
.gap-top-medium{
  padding-top:150px
}
.gap-top-large{
  padding-top:200px
}
.gap-bottom-xxsmall{
  padding-bottom:20px
}
.gap-bottom-xsmall{
  padding-bottom:40px
}
.gap-bottom-small{
  padding-bottom:70px
}
.gap-bottom-medium{
  padding-bottom:150px
}
.gap-bottom-large{
  padding-bottom:200px
}
.blog{
  padding-top:200px
}
.blog .excerpt{
  padding-bottom:40px
}
.blog .excerpt a{
  -webkit-transition:opacity .3s ease-out;
  transition:opacity .3s ease-out;
  margin:14px;
  display:block;
  color:#363636;
  -webkit-box-shadow:0 0 1px 0 rgba(18,32,73,.08),0 8px 32px 0 rgba(55,92,192,.08);
  box-shadow:0 0 1px 0 rgba(18,32,73,.08),0 8px 32px 0 rgba(55,92,192,.08);
  border-radius:4px;
  overflow:hidden;
  background-color:#f7f7f7
}
@media only screen and (min-width:64.0625em){
  .blog .excerpt a{
      height:470px
  }
}
.blog .excerpt a:hover{
  opacity:.85
}
.blog .excerpt a h3,.blog .excerpt a p{
  padding:0 8%;
  line-height:1.4
}
.blog h2.category-title{
  text-transform:capitalize
}
.newsletter{
  margin-bottom:20px;
  border-bottom:1px solid #e3e3e3
}
.newsletter .headline{
  font-weight:400;
  text-align:center;
  border:none;
  padding:0 0 10px 0;
  margin-bottom:0
}
.newsletter .headline small{
  font-size:.469em;
  font-weight:100;
  line-height:1.6;
  max-width:400px;
  margin-top:18px
}
.newsletter p{
  font-size:.938em;
  text-align:center
}
.newsletter form{
  margin:23px 0 59px
}
.newsletter form input[type=email],.newsletter form input[type=number],.newsletter form input[type=password],.newsletter form input[type=text],.newsletter form textarea{
  background:#fff;
  border-color:#cfcfcf
}
.newsletter form #mce-responses{
  font-size:.813em;
  text-align:center
}
.newsletter form #mce-responses .response{
  margin-bottom:15px
}
.newsletter form #mce-responses #mce-error-response{
  color:#e87677
}
.newsletter form #mce-responses #mce-success-response{
  color:#43a047
}
.newsletter form .btn{
  width:100%;
  border:none
}
.newsletter .contact-us{
  text-align:center;
  font-weight:100;
  font-size:.813em;
  margin-bottom:22px
}
.newsletter .contact-us a,.newsletter .contact-us p{
  font-size:inherit;
  margin:0
}
.bottom-signup .newsletter{
  border-top:1px solid #e3e3e3;
  padding-top:20px
}
.post{
  padding-top:130px
}
@media only screen and (min-width:40.0625em){
  .post{
      padding-top:180px
  }
}
.post .headline{
  margin-bottom:20px;
  padding-bottom:10px;
  font-size:2.313em;
  text-align:center;
  line-height:1.2;
  border-bottom:none
}
.post .meta-details{
  text-align:center
}
.post .meta-details span{
  margin:0 10px;
  font-size:15px
}
.post .meta-details span img{
  width:40px;
  height:40px;
  display:inline-block;
  border-radius:100%;
  margin-right:10px
}
.post .meta-details span a{
  font-size:1em
}
@media only screen and (min-width:40.0625em){
  .post .meta-details span{
      font-size:.938em
  }
  .post .meta-details span a{
      font-size:1em
  }
}
.post .body{
  margin-top:50px
}
.post .body p{
  margin:25px 0;
  font-size:1em;
  line-height:1.68;
  letter-spacing:-.003em
}
.post .body p:first-child{
  margin-bottom:60px
}
@media only screen and (min-width:40.0625em){
  .post .body p{
      font-size:1.313em
  }
}
.post .body p strong{
  font-weight:600
}
.post .body p a{
  font-size:1em
}
.post .body p label.photo-label{
  font-size:.619em;
  color:#8d8d8d;
  font-family:Roboto,Helvetica,Arial,sans-serif
}
.post .body p.title{
  margin-bottom:0
}
.post .body ul{
  margin-bottom:25px
}
.post .body ul li{
  font-size:1em;
  padding:8px 0 0 10px;
  line-height:1.68;
  list-style:square;
  margin-left:80px;
  position:relative
}
@media only screen and (min-width:40.0625em){
  .post .body ul li{
      font-size:1.313em
  }
}
.post .body ul li.cons,.post .body ul li.pros{
  list-style:none
}
.post .body ul li.cons:before,.post .body ul li.pros:before{
  position:absolute;
  top:7px;
  left:-26px;
  font-weight:600
}
.post .body ul li.pros:before{
  content:"+";
  color:#43a047
}
.post .body ul li.cons:before{
  content:"-";
  left:-23px;
  color:#e87677
}
.post .body ul li a,.post .body ul li li{
  font-size:1em
}
.post .body ul li strong{
  font-weight:600
}
.post .body h3{
  font-weight:600;
  margin-top:50px
}
.post .body h3 a{
  font-size:inherit
}
.post .body blockquote{
  margin:0 0 30px 20px;
  padding:.6em 1em;
  font-weight:inherit;
  border-left:6px solid #e3e3e3
}
.post .body pre{
  line-height:1
}
.post .body code{
  border:0;
  margin:0;
  padding:0 0 20px 20px;
  font-size:13px;
  font-weight:400;
  font-family:Consolas,"Liberation Mono",Courier,monospace;
  background-color:#edeef0
}
.post .body code.inline{
  margin:0 2px;
  padding:3px 6px;
  display:inline;
  font-size:18px!important;
  word-break:break-word
}
.post .body .toolbox{
  margin:20px 0 40px 0
}
.post .author{
  padding:40px 0 70px
}
.post .author blockquote,.post .author img{
  display:inline-block
}
.post .author blockquote{
  margin-top:10px;
  font-size:1.625em
}
.post .author img{
  border-radius:100%;
  max-width:100px
}
.post .author h5{
  margin:0;
  text-align:left
}
.post .author p{
  margin-top:5px
}
.post .author a{
  font-size:inherit;
  color:#363636
}
#comments{
  margin-top:30px;
  margin-bottom:30px
}
.branding .header-branding{
  background-color:#f7f7f7
}
.branding img{
  width:80%;
  margin-left:auto;
  margin-right:auto;
  display:block;
  position:relative;
  top:35%;
  bottom:50%
}
.branding .border-bottom{
  width:40px;
  margin-bottom:60px;
  margin-top:10px;
  border-bottom:solid 2px #104acc;
  margin-left:20px
}
@media only screen and (min-width:40.0625em){
  .branding .border-bottom{
      margin-left:auto;
      margin-right:auto;
      display:block;
      margin-top:10px;
      margin-bottom:60px
  }
}
.branding svg{
  margin-left:auto;
  margin-right:auto;
  display:block
}
.branding .brand-guides svg{
  width:90%
}
.branding .brand-guides .style-guidelines{
  padding-top:14px
}
.branding .brand-guides .download{
  padding:14px 30px 30px;
  color:#fff;
  background-color:#104acc
}
.branding .logo-inversions .logo-holder{
  margin-left:auto;
  margin-right:auto;
  padding:10px 0;
  display:block;
  background-color:#f7f7f7
}
.branding .logo-inversions .logo-holder-black{
  margin-left:auto;
  margin-right:auto;
  padding:10px 0;
  display:block;
  background-color:#000
}
.branding .logo-inversions .logo{
  margin-top:5%;
  width:250px
}
@media only screen and (min-width:40.0625em){
  .branding .logo-inversions .logo{
      margin-top:5%;
      width:450px
  }
}
.branding .logo-versions .logo-holder{
  padding:20px 0;
  height:200px;
  background-color:#f7f7f7
}
@media only screen and (min-width:40.0625em){
  .branding .logo-versions .logo-holder{
      height:250px
  }
}
.branding .logo-versions .logo{
  margin-top:5%;
  width:220px
}
@media only screen and (min-width:40.0625em){
  .branding .logo-versions .logo{
      margin-top:10%;
      width:280px
  }
}
.branding .logo-versions .logo-two{
  margin-top:5%
}
@media only screen and (min-width:40.0625em){
  .branding .logo-versions .logo-two{
      margin-top:10%
  }
}
.branding .logo-versions .logo-three{
  margin-top:2%;
  width:90%
}
@media only screen and (min-width:40.0625em){
  .branding .logo-versions .logo-three{
      margin-top:7%
  }
}
.branding .logo-colors h4{
  margin:0;
  padding:5px 0 10px;
  font-size:20px
}
.branding .logo-colors .color{
  padding:15px;
  color:#fff
}
.branding .logo-colors .color.brand-blue{
  background-color:#104acc
}
.branding .logo-colors .color.brand-red{
  background-color:#e87677
}
.branding .logo-colors .color.brand-green{
  background-color:#43a047
}
.branding .logo-colors .color.brand-navy{
  background-color:#404b5b
}
.branding .logo-colors .color.brand-yellow{
  background-color:#fad97f;
  color:#363636
}
.branding .logo-spacing .logo-holder{
  padding:40px 0;
  background-color:#f7f7f7
}
.branding .logo-spacing .logo-holder svg{
  width:100%
}
.branding .logo-writing .logo-holder{
  width:80%
}
.contact .consent{
  margin-bottom:10px;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:baseline;
  -ms-flex-align:baseline;
  align-items:baseline
}
.contact .consent input[type=checkbox]{
  width:45px;
  margin-right:10px
}
.contact.get-a-quote{
  background-color:#f5f6fa
}
.contact.get-a-quote form{
  padding:35px 50px 50px!important;
  border-radius:8px;
  border-top:8px solid #104acc;
  -webkit-box-shadow:0 3px 25px rgba(0,0,0,.04);
  box-shadow:0 3px 25px rgba(0,0,0,.04);
  background-color:#fff
}
.contact.get-a-quote form .selectbox,.contact.get-a-quote form input,.contact.get-a-quote form textarea{
  font-size:100%;
  border-radius:4px;
  border-color:#a1a7bd
}
.contact.get-a-quote form .selectbox:focus,.contact.get-a-quote form input:focus,.contact.get-a-quote form textarea:focus{
  -webkit-box-shadow:0 3px 9px rgba(0,0,0,.1);
  box-shadow:0 3px 9px rgba(0,0,0,.1)
}
.contact.get-a-quote form input[type=email],.contact.get-a-quote form input[type=text],.contact.get-a-quote form textarea{
  padding:11px 14px
}
.contact.get-a-quote form textarea{
  min-height:95px
}
.contact.get-a-quote .brand-header{
  color:#8d8d8d
}
.contact.get-a-quote .brands{
  opacity:.7;
  padding-bottom:20px
}
.contact h3{
  font-size:20px;
  line-height:1;
  margin:0 0 14px
}
.contact .office,.contact .partner{
  text-align:left;
  margin-top:31px
}
.contact #form-error,.contact #form-recaptcha-error,.contact #form-sent{
  display:none
}
.contact .notification{
  margin:0 0 20px
}
.contact form{
  padding-bottom:55px
}
@media only screen and (min-width:64.0625em){
  .contact form{
      padding-bottom:0
  }
}
.contact form textarea{
  min-height:135px;
  margin-bottom:6px
}
.contact .tabs>div{
  display:none
}
.contact .tabs>div.active,.contact .tabs>div:first-child{
  display:block
}
.contact .tabs code{
  padding:11px 15px;
  margin-top:20px;
  overflow:auto;
  white-space:nowrap;
  background-color:#fff;
  font-family:Consolas,"Liberation Mono",Courier,monospace;
  border:2px solid #cfcfcf
}
.contact .tabs .cdn-tab-border{
  border-bottom:1px solid #e3e3e3
}
.contact .tabs .extra{
  margin:10px 0 15px 0
}
.download .resources .list{
  width:90%;
  margin:0 auto
}
@media only screen and (min-width:64.0625em){
  .download .resources .list{
      width:50%
  }
}
.download .tabs-download code{
  margin-top:20px;
  padding:16px 20px;
  overflow:auto;
  white-space:pre;
  font-family:Consolas,"Liberation Mono",Courier,monospace;
  border:1px solid #a1a7bd;
  background-color:#fff
}
.download .tabs-download .switch ul{
  text-align:center
}
.download .tabs-download .switch ul li{
  font-size:18px
}
.download .tabs-download .switch ul li.active{
  font-weight:600
}
.download .tabs-download .switch ul li.active:last-child{
  border-color:#43a047
}
.download .tabs-download #tab-content-ce,.download .tabs-download #tab-content-pro{
  background:0 0
}
.download .tabs-download #tab-content-ce li.active{
  border-color:#43a047
}
.customer-story{
  padding-top:70px
}
.customer-story .breadcrumbs{
  margin:97px 0 10px;
  padding-left:20px;
  color:#a1a7bd;
  font-size:.813em
}
@media only screen and (min-width:40.0625em){
  .customer-story .breadcrumbs{
      padding-left:0
  }
}
.customer-story .breadcrumbs a{
  font-size:1em
}
.customer-story .banner{
  width:100%;
  height:490px;
  margin:0 auto 30px;
  padding:92px 20px 20px;
  position:relative;
  color:#fff;
  text-align:center;
  background-color:#002a8d
}
@media only screen and (min-width:40.0625em){
  .customer-story .banner{
      width:100%;
      height:360px
  }
}
.customer-story .banner:after{
  content:'';
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:auto;
  left:auto;
  width:100%;
  height:100%;
  opacity:.25;
  z-index:2;
  background-size:cover
}
.customer-story .banner.akindi:after{
  background-image:url(/static/images/template/ModCustomers/akindi/akindi-bg.png)
}
.customer-story .banner.bittitan:after{
  background-image:url(/static/images/template/ModCustomers/bittitan/bittitan-bg.png)
}
.customer-story .banner.infogram:after{
  background-image:url(/static/images/template/ModCustomers/infogram/infogram-bg.png)
}
.customer-story .banner.nuxeo:after{
  background-image:url(/static/images/template/ModCustomers/nuxeo/nuxeo-bg.png)
}
.customer-story .banner.trackingfirst:after{
  background-image:url(/static/images/template/ModCustomers/trackingfirst/trackingfirst-bg.png)
}
.customer-story .banner.datawrapper:after{
  background-image:url(/static/images/template/ModCustomers/datawrapper/datawrapper-bg.png)
}
.customer-story .banner.socialpoint:after{
  background-image:url(/static/images/template/ModCustomers/socialpoint/socialpoint-bg.png)
}
.customer-story .banner.omnidb:after{
  background-image:url(/static/images/template/ModCustomers/omnidb/omnidb-bg.png)
}
.customer-story .banner.visible:after{
  background-image:url(/static/images/template/ModCustomers/visible/visible-bg.png)
}
.customer-story .banner.amcharts:after{
  background-image:url(/static/images/template/ModCustomers/amcharts/amcharts-bg.png)
}
.customer-story .banner.slicingdice:after{
  background-image:url(/static/images/template/ModCustomers/slicingdice/slicingdice-bg.png)
}
.customer-story .banner.proposalsfactory:after{
  background-image:url(/static/images/template/ModCustomers/proposalsfactory/proposalsfactory-bg.png)
}
.customer-story .banner.labstep:after{
  background-image:url(/static/images/template/ModCustomers/labstep/labstep-bg.png)
}
.customer-story .banner.decisionlens:after{
  background-image:url(/static/images/template/ModCustomers/decisionlens/decisionlens-bg.png)
}
.customer-story .banner.bellawatt:after{
  background-image:url(/static/images/template/ModCustomers/bellawatt/bellawatt-bg.png)
}
.customer-story .banner.datacontroller:after{
  background-image:url(/static/images/template/ModCustomers/datacontroller/datacontroller-bg.png)
}
.customer-story .banner.isams:after{
  background-image:url(/static/images/template/ModCustomers/isams/isams-bg.png)
}
.customer-story .banner.enrich:after{
  background-image:url(/static/images/template/ModCustomers/enrich/enrich-bg.png)
}
.customer-story .banner.openasapp:after{
  background-image:url(/static/images/template/ModCustomers/openasapp/openasapp-bg.png)
}
.customer-story .banner.resolutepts:after{
  background-image:url(/static/images/template/ModCustomers/resolutepts/resolutepts-bg.jpg)
}
.customer-story .banner.ballotpedia:after{
  background-image:url(/static/images/template/ModCustomers/ballotpedia/ballotpedia-bg.jpeg)
}
.customer-story .banner.panthea:after{
  background-image:url(/static/images/template/ModCustomers/panthea/panthea-bg.png)
}
.customer-story .banner.devoteam:after{
  background-image:url(/static/images/template/ModCustomers/devoteam/devoteam-bg.jpg)
}
.customer-story .banner.clearpoint:after{
  background-image:url(/static/images/template/ModCustomers/clearpoint/clearpoint-bg.png)
}
.customer-story .banner.asterisknetworks:after{
  background-image:url(/static/images/template/ModCustomers/asterisknetworks/asterisknetworks-bg.jpg)
}
.customer-story .banner.advisemc:after{
  background-image:url(/static/images/template/ModCustomers/advisemc/advisemc-bg.jpg)
}
.customer-story .banner.magosoft:after{
  background-image:url(/static/images/template/ModCustomers/magosoft/magosoft-bg.jpg)
}
.customer-story .banner h1,.customer-story .banner h2,.customer-story .banner img{
  margin:0;
  position:relative;
  text-align:center;
  z-index:3
}
.customer-story .banner h1{
  margin:40px 0 20px;
  font-weight:400;
  font-size:30px
}
.customer-story .content h3{
  margin:40px 0 0;
  font-weight:600
}
.customer-story .content a,.customer-story .content p{
  font-size:1.125em;
  line-height:1.8
}
.customer-story .content p>a,.customer-story .content ul li>a{
  font-size:1em
}
.customer-story .content ul.list li{
  margin-left:50px;
  padding:8px 0 0 10px;
  font-size:1.125em;
  list-style:square
}
.customer-story .content p.photo-credit{
  margin:5px 0 0;
  font-size:.688em;
  color:#8d8d8d
}
.customer-story .content a.download{
  font-size:.813em
}
.customer-story .content img.illustration{
  margin-top:20px
}
.customer-story .content div.customer-video{
  height:0;
  margin-top:60px;
  padding-bottom:56.25%;
  padding-top:30px;
  position:relative;
  overflow:hidden
}
.customer-story .content div.customer-video embed,.customer-story .content div.customer-video iframe,.customer-story .content div.customer-video object{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.customer-story .content .head-testimony{
  padding:5px 0 20px
}
@media only screen and (min-width:40.0625em){
  .customer-story .content .head-testimony{
      padding:40px 0 20px
  }
}
.customer-story .content .head-testimony h4{
  position:relative;
  font-size:24px;
  line-height:1.5
}
.customer-story .content .head-testimony h4 .quotation-mark{
  position:absolute;
  top:-20px;
  left:-40px
}
.customer-story .company-details{
  margin-top:60px;
  margin-bottom:90px;
  text-align:center
}
.customer-story .company-details img{
  width:135px;
  height:135px
}
.customer-story .company-details p{
  margin:0;
  font-size:15px
}
.customer-story .company-details .name{
  margin-top:20px;
  font-weight:600;
  line-height:1
}
.customer-story .company-details ul{
  margin-top:30px
}
.customer-story .company-details ul li{
  margin:10px 0
}
.customer-story .company-details ul li svg{
  width:16px;
  height:16px;
  position:relative;
  top:3px;
  left:-5px;
  fill:#a1a7bd
}
.customer-story .company-details ul li a{
  font-size:1em
}
.customers .customer-excerpt{
  max-width:340px;
  height:340px;
  display:block
}
.customers .customer-excerpt:after{
  width:100%;
  height:100%
}
.customers .customer{
  margin:0 auto 50px;
  position:relative;
  color:#fff;
  background-color:#002372
}
.customers .customer:hover:after{
  opacity:.35
}
.customers .customer:after{
  content:'';
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:auto;
  left:auto;
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  opacity:.25;
  z-index:2;
  background-size:cover
}
.customers .customer.akindi:after{
  background-image:url(/static/images/template/ModCustomers/akindi/akindi-el.png)
}
.customers .customer.bittitan:after{
  background-image:url(/static/images/template/ModCustomers/bittitan/bittitan-el.png)
}
.customers .customer.nuxeo:after{
  background-image:url(/static/images/template/ModCustomers/nuxeo/nuxeo-el.png)
}
.customers .customer.trackingfirst:after{
  background-image:url(/static/images/template/ModCustomers/trackingfirst/trackingfirst-el.png)
}
.customers .customer.infogram:after{
  background-image:url(/static/images/template/ModCustomers/infogram/infogram-el.png)
}
@media only screen and (min-width:64.0625em){
  .customers .customer.infogram:after{
      background-image:url(/static/images/template/ModCustomers/infogram/Infogram-wide-bg.png)
  }
}
.customers .customer.datawrapper:after{
  background-image:url(/static/images/template/ModCustomers/datawrapper/datawrapper-el.png)
}
.customers .customer.socialpoint:after{
  background-image:url(/static/images/template/ModCustomers/socialpoint/socialpoint-el.png)
}
.customers .customer.omnidb:after{
  background-image:url(/static/images/template/ModCustomers/omnidb/omnidb-el.png)
}
.customers .customer.visible:after{
  background-image:url(/static/images/template/ModCustomers/visible/visible-el.png)
}
.customers .customer.amcharts:after{
  background-image:url(/static/images/template/ModCustomers/amcharts/amcharts-el.png)
}
.customers .customer.slicingdice:after{
  background-image:url(/static/images/template/ModCustomers/slicingdice/slicingdice-el.png)
}
.customers .customer.proposalsfactory:after{
  background-image:url(/static/images/template/ModCustomers/proposalsfactory/proposalsfactory-el.png)
}
.customers .customer.labstep:after{
  background-image:url(/static/images/template/ModCustomers/labstep/labstep-el.png)
}
.customers .customer.decisionlens:after{
  background-image:url(/static/images/template/ModCustomers/decisionlens/decisionlens-el.png)
}
.customers .customer.bellawatt:after{
  background-image:url(/static/images/template/ModCustomers/bellawatt/bellawatt-el.png)
}
.customers .customer.datacontroller:after{
  background-image:url(/static/images/template/ModCustomers/datacontroller/datacontroller-el.png)
}
.customers .customer.isams:after{
  background-image:url(/static/images/template/ModCustomers/isams/isams-el.png)
}
.customers .customer.enrich:after{
  background-image:url(/static/images/template/ModCustomers/enrich/enrich-el.png)
}
.customers .customer.openasapp:after{
  background-image:url(/static/images/template/ModCustomers/openasapp/openasapp-el.png)
}
.customers .customer.resolutepts:after{
  background-image:url(/static/images/template/ModCustomers/resolutepts/resolutepts-el.png)
}
.customers .customer.ballotpedia:after{
  background-image:url(/static/images/template/ModCustomers/ballotpedia/ballotpedia-el.jpeg)
}
.customers .customer.panthea:after{
  background-image:url(/static/images/template/ModCustomers/panthea/panthea-el.png)
}
.customers .customer.devoteam:after{
  background-image:url(/static/images/template/ModCustomers/devoteam/devoteam-el.jpg)
}
.customers .customer.clearpoint:after{
  background-image:url(/static/images/template/ModCustomers/clearpoint/clearpoint-el.png)
}
.customers .customer.asterisknetworks:after{
  background-image:url(/static/images/template/ModCustomers/asterisknetworks/asterisknetworks-illustration-2.jpg)
}
.customers .customer.advisemc:after{
  background-image:url(/static/images/template/ModCustomers/advisemc/advisemc-el.jpg)
}
.customers .customer.magosoft:after{
  background-image:url(/static/images/template/ModCustomers/magosoft/magosoft-el.jpg)
}
.customers .customer.coming-soon{
  cursor:default;
  background-color:#e3e3e3
}
.customers .customer h3,.customers .customer img{
  z-index:3
}
.customers .customer img{
  position:absolute;
  top:40px;
  right:40px
}
.customers .customer h3{
  position:absolute;
  bottom:20px;
  left:30px;
  padding-right:20px;
  line-height:1.2em
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide{
      max-width:100%
  }
}
.customers .excerpt-wide .customer-photo{
  display:none
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide .customer-photo{
      position:absolute;
      top:-28px;
      left:50px;
      display:block
  }
}
.customers .excerpt-wide .customer-logo{
  width:120px
}
.customers .excerpt-wide .quotation{
  position:absolute;
  top:74px;
  left:460px;
  display:none
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide .quotation{
      display:block
  }
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide .infogram-heading{
      position:absolute;
      top:72px;
      left:510px;
      width:41%
  }
}
.customers .excerpt-wide .infogram-heading .title{
  font-size:28px
}
.customers .excerpt-wide .infogram-heading .title.title-long{
  display:none
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide .infogram-heading .title.title-short{
      display:none
  }
  .customers .excerpt-wide .infogram-heading .title.title-long{
      display:block
  }
}
.customers .excerpt-wide .infogram-heading .signature{
  margin-top:20px;
  font-size:16px;
  display:none
}
@media only screen and (min-width:64.0625em){
  .customers .excerpt-wide .infogram-heading .signature{
      display:block
  }
}
.customers .header-background{
  height:100vh;
  max-height:520px;
  color:#f7f7f7;
  background:#000 url(/static/images/template/ModCustomers/housemark/housemark-video-bg.jpg) no-repeat 50% 0
}
@media only screen and (min-width:64.0625em){
  .customers .header-background{
      height:85vh;
      min-height:570px;
      max-height:660px
  }
}
.customers .header-background .video-play{
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  max-height:90px;
  max-width:91px;
  opacity:.55
}
.customers .header-background .video-play:hover{
  opacity:.3
}
.customers .header-background h2{
  line-height:1.1em;
  position:relative
}
@media only screen and (min-width:64.0625em){
  .customers .header-background h2{
      font-size:43px
  }
}
.customers .header-background h2 img{
  position:absolute;
  top:-20px;
  left:-50px;
  display:none
}
@media only screen and (min-width:64.0625em){
  .customers .header-background h2 img{
      display:block
  }
}
.customers .header-background .testimony-brand{
  font-size:18px;
  color:#fff
}
.customers .header-background .testimony-brand svg{
  max-height:38px;
  max-width:40px
}
.customers .header-background .testimony-brand span{
  position:relative;
  top:-11px;
  left:10px;
  font-size:20px
}
.demo .brand-section h4{
  font-size:18px;
  font-weight:400
}
.demo .brand-section .brands ul{
  margin:0
}
.demo .brand-section .brands ul li img{
  padding:4px;
  -webkit-filter:none;
  filter:none
}
.demo .tabs .tab{
  border-radius:8px 8px 0 0;
  background-color:#00309b
}
.demo .tabs .tab ul{
  display:inline-block
}
.demo .tabs .tab ul:nth-child(2){
  float:right
}
.demo .tabs .tab ul li{
  padding:11px 14px 7px;
  color:#fff
}
.demo .tabs .tab ul li:hover{
  color:#b5dafd
}
.demo .tabs .tab ul li.active{
  border-color:#00e5ff
}
.demo .tabs .tab ul li.active:hover{
  color:#fff
}
.demo .tabs .tab ul li.frameworks-label,.demo .tabs .tab ul li.frameworks-label:hover{
  color:#fff;
  cursor:default
}
.demo .tabs .tab ul li svg{
  width:17px;
  height:17px;
  top:3px
}
.demo .tabs>div:not(.tab){
  padding:14px 0;
  border-radius:0 0 8px 8px
}
.demo #tab-content-preview{
  min-height:488px
}
#hot{
  margin:5px 20px
}
.handsontable{
  font-size:13px;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,"Helvetica Neue",Arial,sans-serif;
  font-weight:400
}
.collapsibleIndicator{
  text-align:center
}
.handsontable .htRight .changeType{
  margin:3px 1px 0 13px
}
table.htCore td.star{
  color:#fcb515
}
table.htCore tr.odd td{
  background:#fafbff
}
table.htCore td .progressBar{
  background:#37bc6c;
  height:10px
}
table.htCore tr.selected td{
  background:#edf3fd
}
.handsontable th input[type=checkbox]{
  position:relative;
  top:3px
}
.knowledge-base h2{
  text-align:left;
  line-height:1.1em;
  font-weight:400
}
@media only screen and (min-width:40.0625em){
  .knowledge-base h2{
      margin-top:0;
      text-align:center
  }
}
.knowledge-base h3{
  margin:30px 0;
  font-weight:500
}
.knowledge-base h4{
  margin:10px 0 0;
  padding:0;
  font-weight:400;
  text-align:left
}
.knowledge-base h4 a{
  font-size:16px
}
.knowledge-base h5{
  margin:0;
  padding:0;
  color:#104acc;
  line-height:2em;
  font-weight:400
}
.knowledge-base .bottom-section{
  margin:60px 0 0;
  padding:0 0 90px;
  background:#104acc;
  text-align:center
}
.knowledge-base .bottom-section h2{
  color:#f7f7f7
}
.knowledge-base .answers ul{
  margin-left:20px
}
.knowledge-base .answers ul li{
  font-weight:400;
  list-style-type:disc;
  list-style-position:inside
}
.knowledge-base .answers>div{
  -webkit-transition:height .5s ease-in-out;
  transition:height .5s ease-in-out;
  height:0;
  margin:5px 0 5px 20px;
  padding-left:20px;
  overflow:hidden;
  border-left:10px solid #f5f6fa
}
.knowledge-base .answers>div p{
  padding:10px 0
}
.knowledge-base .answers img{
  margin:10px 0
}
.features .all-features{
  padding:60px 0 70px
}
.features .get-started{
  padding:40px 0 0
}
@media only screen and (min-width:64.0625em){
  .features .get-started{
      padding:60px 0 40px
  }
}
.features .get-started h2{
  font-size:30px
}
@media only screen and (min-width:64.0625em){
  .features .get-started h2{
      font-size:34px
  }
}
.features .get-started h2 span{
  margin:20px 0 30px
}
@media only screen and (min-width:64.0625em){
  .features .get-started h2 span{
      width:30%
  }
}
.features .get-started .btn{
  margin:0 10px 10px;
  white-space:nowrap
}
@media only screen and (min-width:64.0625em){
  .features .plans{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      margin-bottom:-40px
  }
}
.features .plans .ce-background-green{
  width:98%;
  margin:20px auto;
  padding:20px;
  background-color:#43a047;
  border:1px solid #43a047;
  border-radius:5px
}
@media only screen and (min-width:40.0625em){
  .features .plans .ce-background-green{
      width:49%;
      margin:20px 1% 20px 0;
      padding:20px 40px 20px 0;
      border-radius:0 5px 5px 0;
      text-align:right
  }
}
@media only screen and (min-width:40.0625em){
  .features .plans .ce-background-green .holder{
      display:inline-block
  }
}
.features .plans .ce-background-green .product-button-ce{
  padding:14px 0 0 0;
  float:none;
  text-align:center
}
.features .plans .ce-background-green .product-button-ce a{
  color:#43a047
}
@media only screen and (min-width:40.0625em){
  .features .plans .ce-background-green .product-button-ce{
      padding:7px 0 0 20px;
      display:inline;
      float:right
  }
}
.features .plans .ce-background-green h1,.features .plans .ce-background-green h4{
  color:#f7f7f7;
  text-align:center
}
@media only screen and (min-width:40.0625em){
  .features .plans .ce-background-green h1,.features .plans .ce-background-green h4{
      text-align:right
  }
}
.features .plans .ce-background-green h1{
  margin:0;
  padding:0;
  font-weight:600
}
.features .plans .ce-background-green h4{
  margin:0;
  padding:0;
  font-weight:400
}
.features .plans .pro-background-blue{
  width:98%;
  margin:10px auto;
  padding:20px;
  text-align:center;
  background-color:#104acc;
  border:1px solid #104acc;
  border-radius:5px
}
@media only screen and (min-width:64.0625em){
  .features .plans .pro-background-blue{
      width:49%;
      margin:20px 0 20px 20px;
      padding:20px 0 20px 40px;
      border-radius:5px 0 0 5px;
      text-align:left
  }
}
@media only screen and (min-width:64.0625em){
  .features .plans .pro-background-blue .holder{
      display:inline-block
  }
}
.features .plans .pro-background-blue .product-button-pro{
  float:none;
  display:block;
  text-align:center;
  padding:14px 0 0 0
}
@media only screen and (min-width:64.0625em){
  .features .plans .pro-background-blue .product-button-pro{
      padding:7px 20px 0 0;
      display:inline-block;
      float:left
  }
}
.features .plans .pro-background-blue h1,.features .plans .pro-background-blue h4{
  color:#f7f7f7;
  text-align:center
}
@media only screen and (min-width:64.0625em){
  .features .plans .pro-background-blue h1,.features .plans .pro-background-blue h4{
      text-align:left
  }
}
.features .plans .pro-background-blue h1{
  margin:0;
  padding:0;
  font-weight:600
}
.features .plans .pro-background-blue h4{
  margin:0;
  padding:0;
  font-weight:400
}
.features .live-demo{
  min-height:502px
}
.features .live-demo>div:first-child{
  display:none
}
@media only screen and (min-width:64.0625em){
  .features .live-demo>div:first-child{
      display:block
  }
}
.features .live-demo h5{
  margin:20px 0 5px;
  padding:0;
  text-align:left
}
@media only screen and (min-width:40.0625em){
  .features .live-demo h5{
      margin:0 0 5px
  }
}
.features .live-demo .tabs{
  color:#fff
}
.features .live-demo .tabs>div:not(.tab){
  padding:0
}
.features .live-demo .plugin-preview{
  margin-top:46px;
  background:#fff
}
.features .live-demo #example-error{
  width:26px;
  height:26px;
  position:absolute;
  top:6px;
  right:22px;
  display:none;
  color:#fff;
  font-weight:600;
  line-height:26px;
  text-align:center;
  border-radius:4px;
  z-index:10;
  background:#e25354
}
.features .live-demo .CodeMirror{
  height:456px;
  font-size:12px
}
.features .live-demo .CodeMirror span{
  font-size:12px
}
.features .live-demo .example-error{
  position:absolute;
  top:80px;
  left:40px
}
.features .tabs-code>div{
  display:none
}
.features .tabs-code>div.active,.features .tabs-code>div:first-child{
  display:block
}
.features .main-background{
  background:#3473fe;
  background:linear-gradient(170deg,#3473fe 40%,#104acd 100%);
  -webkit-box-shadow:0 3px 10px rgba(0,0,0,.1),0 3px 10px rgba(0,0,0,.1);
  box-shadow:0 3px 10px rgba(0,0,0,.1),0 3px 10px rgba(0,0,0,.1)
}
.features .header-features{
  padding:60px 10px 0;
  color:#f7f7f7
}
@media only screen and (min-width:40.0625em){
  .features .header-features{
      padding:140px 10px 0
  }
}
.features h3{
  padding:0 10px;
  margin:50px 0 2px;
  font-weight:500
}
@media only screen and (min-width:40.0625em){
  .features h3{
      padding-bottom:2px
  }
}
.features .brands{
  margin-top:70px
}
.features .brands ul{
  margin:0
}
.features .border-bottom{
  width:40px;
  margin:10px 0 20px;
  border-bottom:solid 2px #104acc
}
@media only screen and (min-width:40.0625em){
  .features .border-bottom{
      margin:10px auto 20px;
      display:block
  }
}
.features .table-pad{
  margin:0 auto -50px auto;
  display:block
}
@media only screen and (min-width:40.0625em){
  .features .table-pad{
      margin:0 auto -150px;
      display:block
  }
}
.features .cta{
  margin:10px auto 20px;
  padding:60px 10px 0;
  text-align:center;
  clear:both;
  display:block
}
@media only screen and (min-width:40.0625em){
  .features .cta{
      margin:20px auto;
      padding:0
  }
}
.features a,.features p{
  line-height:1.6
}
.features p{
  padding:0 10px
}
@media only screen and (min-width:40.0625em){
  .features p{
      padding:0
  }
}
.features .p-icon{
  margin-left:0
}
@media only screen and (min-width:40.0625em){
  .features .p-icon{
      margin-left:87px
  }
}
.features .svg-icon-fiddle{
  width:20px;
  height:20px;
  fill:#104acc;
  margin:0 10px 0 20px
}
@media only screen and (min-width:40.0625em){
  .features .svg-icon-fiddle{
      margin:0 10px 0 20px;
      float:left
  }
}
.features .svg-icon{
  width:160px;
  height:160px;
  margin:20px 0 0 0;
  padding:0
}
@media only screen and (min-width:40.0625em){
  .features .svg-icon{
      width:75px;
      height:75px;
      margin:36px 10px 50px 0;
      float:left
  }
}
.features .testimony{
  padding:40px 0 22px;
  margin:12px auto 22px
}
.features .testimony h5{
  margin-top:10px;
  font-size:16px;
  font-weight:400
}
.features .testimony img{
  border-radius:100%
}
.features .testimony blockquote{
  margin:6px 0 0;
  font-weight:300;
  font-size:26px
}
.frameworks #form-error,.frameworks #form-recaptcha-error,.frameworks #form-sent{
  display:none;
  border-radius:4px
}
.frameworks .notification{
  margin:0 0 20px
}
.frameworks form{
  margin-bottom:52px;
  padding:25px 40px 40px!important;
  border-radius:8px;
  -webkit-box-shadow:0 3px 25px rgba(0,0,0,.04);
  box-shadow:0 3px 25px rgba(0,0,0,.04);
  color:#363636;
  background-color:#fff
}
.frameworks form .selectbox,.frameworks form input,.frameworks form textarea{
  font-size:100%;
  border-radius:4px;
  border-color:#a1a7bd
}
.frameworks form .selectbox:focus,.frameworks form input:focus,.frameworks form textarea:focus{
  -webkit-box-shadow:0 3px 9px rgba(0,0,0,.1);
  box-shadow:0 3px 9px rgba(0,0,0,.1)
}
.frameworks form input[type=email],.frameworks form input[type=text],.frameworks form textarea{
  margin-bottom:8px;
  padding:11px 14px
}
.frameworks form textarea{
  min-height:95px
}
.frameworks form .btn{
  width:100%
}
.frameworks form .consent{
  margin-bottom:10px;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:baseline;
  -ms-flex-align:baseline;
  align-items:baseline
}
.frameworks form .consent input[type=checkbox]{
  width:45px;
  margin-right:10px
}
.frameworks form .consent label,.frameworks form .consent label a{
  font-size:13px
}
.frontpage.frameworks .highlights ul{
  margin-left:40px;
  list-style:square
}
.frontpage.frameworks .highlights ul li{
  list-style:square;
  font-size:inherit
}
.frontpage.frameworks .header-background{
  height:auto;
  min-height:0;
  max-height:none;
  background:#000 url(/static/images//template/ModFrameworks/laptop-screen-bg.jpg) no-repeat;
  background-size:cover
}
.frontpage.frameworks .header-background .top-banner{
  margin-top:20px;
  position:relative
}
@media only screen and (min-width:64.0625em){
  .frontpage.frameworks .header-background .top-banner{
      margin-top:90px
  }
}
.frontpage.frameworks .header-background .top-banner h1{
  margin-top:96px;
  white-space:normal
}
@media only screen and (min-width:64.0625em){
  .frontpage.frameworks .header-background{
      min-height:0
  }
}
.frameworks .testimony .bubble .quote{
  font-size:18px
}
@media only screen and (min-width:64.0625em){
  .frontpage .brands-frontpage{
      padding:24px 0 4px
  }
}
.frontpage .brands-frontpage h4{
  margin:30px 0 60px;
  padding:0;
  text-align:left;
  font-weight:400;
  font-size:16px;
  color:#fff
}
@media only screen and (min-width:64.0625em){
  .frontpage .brands-frontpage h4{
      margin:7px 0 0
  }
}
.frontpage .brands-frontpage ul li{
  padding:0;
  text-align:center;
  line-height:50%
}
.frontpage .get-started{
  padding:40px 0 0
}
@media only screen and (min-width:64.0625em){
  .frontpage .get-started{
      padding:60px 0 40px
  }
}
.frontpage .get-started h2{
  font-size:30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .get-started h2{
      font-size:34px
  }
}
.frontpage .get-started h2 span{
  margin:20px 0 30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .get-started h2 span{
      width:30%
  }
}
.frontpage .get-started .btn{
  margin:0 10px 10px;
  white-space:nowrap
}
.frontpage .open-source{
  padding:40px 0 60px
}
@media only screen and (min-width:64.0625em){
  .frontpage .open-source{
      padding:60px 0 40px
  }
}
.frontpage .open-source h2{
  font-size:30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .open-source h2{
      font-size:34px
  }
}
.frontpage .open-source h2 span{
  margin:20px 0
}
@media only screen and (min-width:64.0625em){
  .frontpage .open-source h2 span{
      width:30%;
      margin-bottom:50px
  }
}
.frontpage .open-source p{
  font-size:16px
}
.frontpage .open-source p:first-child{
  margin-top:30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .open-source p:first-child{
      margin-top:120px
  }
}
.frontpage .open-source .chart{
  position:relative
}
.frontpage .open-source .chart .cloud{
  width:128px;
  height:64px;
  display:none;
  margin:auto;
  padding:14px 0 9px;
  border-radius:6px;
  line-height:.7;
  color:#fff
}
@media only screen and (min-width:64.0625em){
  .frontpage .open-source .chart .cloud{
      margin-top:120px;
      display:block
  }
}
.frontpage .open-source .chart .cloud strong{
  font-size:28px;
  font-weight:400
}
.frontpage .open-source .chart .cloud span{
  display:block;
  font-size:15px
}
.frontpage .open-source .chart .cloud.issues{
  position:absolute;
  top:-50px;
  left:60px;
  background-color:#104acc
}
.frontpage .open-source .chart .cloud.commits{
  position:absolute;
  top:50px;
  right:30px;
  background-color:#59a500
}
.frontpage .open-source .chart .cloud.stars{
  position:absolute;
  bottom:60px;
  left:200px;
  background-color:#404b5b
}
.frontpage .open-source .chart img{
  max-height:420px
}
.frontpage .highlights{
  padding-bottom:60px;
  position:relative
}
.frontpage .highlights>div.row{
  margin-top:40px
}
@media only screen and (min-width:64.0625em){
  .frontpage .highlights>div.row{
      margin-top:110px
  }
}
.frontpage .highlights>div.row .artwork{
  display:none
}
@media only screen and (min-width:40.0625em){
  .frontpage .highlights>div.row .artwork{
      display:block
  }
}
.frontpage .highlights h2{
  margin-bottom:30px;
  font-size:30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .highlights h2{
      font-size:34px
  }
}
.frontpage .highlights p{
  font-size:18px
}
.frontpage .highlights .benefits-testimonial{
  margin-top:30px
}
.frontpage .highlights .benefits-testimonial img{
  margin-top:3px
}
.frontpage .highlights .benefits-testimonial p{
  margin-top:5px;
  font-size:15px;
  font-weight:400
}
.frontpage .highlights .benefits-testimonial p:first-child{
  margin:0
}
.frontpage .header-laptop{
  min-width:769px;
  min-height:463px;
  display:none
}
@media only screen and (min-width:64.0625em){
  .frontpage .header-laptop{
      margin-top:-20px;
      margin-left:-120px;
      display:block
  }
}
.frontpage .header-background{
  height:50vh;
  min-height:684px;
  max-height:830px;
  position:relative;
  overflow:hidden;
  background:#003eca
}
@media only screen and (min-width:64.0625em){
  .frontpage .header-background{
      height:86vh
  }
}
.frontpage .svg{
  width:550px;
  height:370px;
  margin-top:-60px
}
.frontpage .testimony{
  padding:40px 0 60px
}
@media only screen and (min-width:64.0625em){
  .frontpage .testimony{
      padding:60px 0 120px
  }
}
.frontpage .testimony h2{
  font-size:30px
}
@media only screen and (min-width:64.0625em){
  .frontpage .testimony h2{
      font-size:34px
  }
}
.frontpage .testimony h2 span{
  margin:20px 0 0
}
@media only screen and (min-width:64.0625em){
  .frontpage .testimony h2 span{
      width:30%
  }
}
.frontpage .testimony .bubble{
  width:90%;
  margin:40px auto 0
}
.frontpage .testimony .bubble .quote{
  padding:18px 30px 22px;
  position:relative;
  display:block;
  border-radius:6px;
  -webkit-box-shadow:0 3px 14px rgba(0,0,0,.04),0 4px 14px rgba(0,0,0,.04);
  box-shadow:0 3px 14px rgba(0,0,0,.04),0 4px 14px rgba(0,0,0,.04);
  background-color:#fff
}
.frontpage .testimony .bubble .quote .triangle{
  border-left:7px solid transparent;
  border-right:7px solid transparent;
  border-top:10px solid #fff;
  position:absolute;
  bottom:-9px;
  left:20px
}
.frontpage .testimony .bubble .benefits-testimonial{
  margin-top:30px
}
.frontpage .testimony .bubble .benefits-testimonial img{
  max-width:42px
}
.frontpage .testimony .bubble .benefits-testimonial p{
  margin:0;
  font-size:14px;
  font-weight:400
}
.frontpage .testimony .bubble .benefits-testimonial p:first-child{
  margin-top:2px;
  font-weight:300
}
.frontpage .top-banner{
  content:'';
  display:block;
  position:absolute;
  top:40%;
  right:0;
  bottom:0;
  left:0;
  margin-top:-190px;
  color:#fff
}
@media only screen and (min-width:64.0625em){
  .frontpage .top-banner{
      content:'';
      display:block;
      position:absolute;
      top:50%;
      right:0;
      bottom:0;
      left:0;
      margin-top:-192px
  }
}
.frontpage .top-banner h1{
  padding:0;
  font-weight:600;
  font-size:40px;
  text-align:left
}
@media only screen and (min-width:64.0625em){
  .frontpage .top-banner h1{
      font-size:50px;
      white-space:nowrap;
      line-height:1.2em
  }
}
.frontpage .top-banner h1 span{
  margin-top:16px;
  display:inline-block;
  line-height:1.3em;
  font-weight:400;
  font-size:20px
}
.frontpage .top-banner h1 a{
  color:#fff;
  border-bottom:1px solid #fff
}
.frontpage .top-banner h2{
  margin-top:30px;
  font-weight:400;
  font-size:18px;
  text-align:left;
  line-height:1.3em
}
.frontpage .top-banner .btn{
  white-space:nowrap
}
.frontpage .top-banner p a.release-note{
  color:#fff
}
.frontpage .top-banner p a.release-note:hover{
  opacity:.8
}
.pricing .brands-pricing{
  padding-bottom:30px;
  width:80%
}
.pricing .brands-pricing h4{
  margin:60px 0 50px;
  font-weight:400;
  font-size:20px
}
.pricing .brands-pricing .brands img{
  padding:14px;
  max-width:none;
  max-height:none;
  -webkit-filter:none;
  filter:none
}
.pricing .faq{
  padding:30px 0
}
.pricing .faq .question-item h4{
  margin-top:60px;
  font-size:16px;
  font-weight:600
}
.pricing .faq .question-item p{
  margin:0 0 90px;
  text-align:left
}
@media only screen and (min-width:64.0625em){
  .pricing .faq .question-item p{
      margin-bottom:0;
      padding-right:40px;
      min-height:140px;
      text-align:left
  }
}
.pricing .faq .read-more{
  padding:60px 20px 0;
  text-align:center;
  clear:both;
  display:block;
  margin:60px auto 0
}
@media only screen and (min-width:40.0625em){
  .pricing .faq .read-more{
      padding:0;
      margin:60px auto 90px
  }
}
.pricing .pricing-plans{
  max-width:90%;
  padding:0!important;
  position:relative
}
@media only screen and (min-width:64.0625em){
  .pricing .pricing-plans{
      max-width:82%
  }
}
.pricing .pricing-plans .columns{
  padding:8px
}
.pricing .pricing-plans .plan{
  margin-bottom:20px;
  padding:32px 0 40px;
  position:relative;
  border-radius:8px;
  background-color:#fff
}
@media only screen and (min-width:64.0625em){
  .pricing .pricing-plans .plan{
      min-height:531px;
      margin-bottom:0
  }
}
.pricing .pricing-plans .plan.perpetual{
  min-height:auto
}
.pricing .pricing-plans .plan.dark{
  color:#fff;
  background-color:#002a8d
}
.pricing .pricing-plans .plan.dark .tick{
  fill:#fff
}
.pricing .pricing-plans .plan>.perpetual-content{
  max-width:600px;
  margin:0 auto;
  padding:0 40px
}
.pricing .pricing-plans .plan h3{
  margin:0;
  font-size:26px
}
.pricing .pricing-plans .plan p.badge{
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  position:absolute;
  top:-24px;
  left:50%;
  padding:1px 12px;
  display:block;
  color:#fff;
  font-size:14px;
  border-radius:6px;
  background-color:#104acc
}
.pricing .pricing-plans .plan p.price{
  margin:16px 0 0;
  font-size:26px;
  font-weight:700
}
.pricing .pricing-plans .plan p.term{
  margin-top:0;
  font-size:14px
}
.pricing .pricing-plans .plan p.get-a-quote{
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  margin-top:0;
  position:absolute;
  left:50%
}
.pricing .pricing-plans .plan p.get-a-quote a{
  font-size:15px
}
.pricing .pricing-plans .plan ul{
  width:78%;
  margin:40px auto 0;
  text-align:center
}
@media only screen and (min-width:64.0625em){
  .pricing .pricing-plans .plan ul{
      text-align:left
  }
}
.pricing .pricing-plans .plan ul li{
  padding:5px 0;
  font-size:15px
}
.pricing .pricing-plans .plan ul li svg{
  width:15px;
  height:15px;
  position:relative;
  top:2px;
  left:-7px;
  fill:#104acc
}
.pricing .pricing-plans .plan ul li>span{
  font-size:15px
}
.pricing .pricing-plans .plan ul li.hint{
  position:relative;
  cursor:default
}
.pricing .pricing-plans .plan ul li.hint span{
  padding-bottom:2px;
  border-bottom:1px dashed #cfcfcf;
  font-size:15px
}
.pricing .pricing-plans .plan ul li.hint .tooltip-inner{
  position:absolute;
  top:34px;
  left:19px;
  -webkit-transition:opacity .3s ease;
  transition:opacity .3s ease;
  min-width:220px;
  padding:10px 20px;
  display:none;
  font-size:13px;
  line-height:1.5;
  text-align:left;
  cursor:default;
  visibility:hidden;
  opacity:0;
  z-index:100;
  border-radius:8px;
  pointer-events:none;
  -webkit-box-shadow:0 2px 13px 0 rgba(68,68,68,.2);
  box-shadow:0 2px 13px 0 rgba(68,68,68,.2);
  background-color:#fff
}
@media only screen and (min-width:64.0625em){
  .pricing .pricing-plans .plan ul li.hint .tooltip-inner{
      display:block
  }
}
.pricing .pricing-plans .plan ul li.hint .tooltip-inner ul li{
  white-space:normal;
  line-height:1.1
}
.pricing .pricing-plans .plan ul li.hint:hover span{
  border-color:#8d8d8d
}
.pricing .pricing-plans .plan ul li.hint:hover .tooltip-inner{
  visibility:visible;
  opacity:1;
  color:#363636
}
.pricing .pricing-plans .plan .btn{
  width:60%;
  margin:10px auto
}
.pricing .purchase-options .purchase-item h3{
  font-weight:400;
  margin:0 0 10px 0;
  text-align:center
}
.pricing .purchase-options .purchase-item .top{
  padding-top:20px
}
.pricing .purchase-options .purchase-item p{
  margin:0 20px 20px;
  text-align:center
}
@media only screen and (min-width:40.0625em){
  .pricing .purchase-options .purchase-item p{
      text-align:center
  }
}
.pricing .purchase-options .purchase-item p a{
  font-size:1em;
  text-align:center;
  line-height:2em
}
.pricing .purchase-options .svg-icon{
  width:140px;
  height:140px;
  margin:60px auto 30px;
  display:block
}
@media only screen and (min-width:40.0625em){
  .pricing .purchase-options .svg-icon{
      width:80px;
      height:80px;
      margin-bottom:30px
  }
}
.pricing .tabs-pricing>div{
  display:none
}
.pricing .tabs-pricing>div.active,.pricing .tabs-pricing>div:first-child{
  display:block
}
.pricing .tabs-pricing .tab{
  margin-bottom:20px;
  text-align:center
}
.pricing .tabs-pricing .tab ul li{
  margin:3px;
  padding:0 5px;
  display:inline-block;
  -webkit-transition:.15s;
  transition:.15s
}
.pricing .tabs-pricing .tab ul li:hover{
  color:#8d8d8d
}
.pricing .tabs-pricing .tab ul li.active{
  font-weight:600;
  border-bottom:3px solid #002a8d
}
.pricing .tabs-pricing .tab ul li>*{
  pointer-events:none
}
.privacy{
  text-align:justify
}
.privacy li{
  padding:10px 30px
}
.privacy h4{
  font-weight:400;
  margin:30px 0 10px 0;
  text-align:left;
  padding:0
}
.privacy p{
  font-size:15px;
  margin:0;
  padding-top:20px
}
.privacy a{
  font-size:inherit
}
.purchase .brands-purchase{
  width:80%
}
.purchase .brands-purchase h4{
  margin:60px 0;
  font-weight:400;
  font-size:18px
}
.purchase .brands-purchase .brands img{
  padding:14px;
  max-width:none;
  max-height:none;
  -webkit-filter:none;
  filter:none;
  zoom:1.5
}
.checkout-container{
  border:1px solid #edeef0;
  border-radius:4px;
  background-color:#f5f6fa
}
.checkout-container h3{
  margin-bottom:10px;
  font-size:18px
}
.checkout-container .purchase-plan{
  padding:22px 30px 40px 10px
}
.checkout-container .purchase-plan .purchase-name h2{
  font-size:18px
}
.checkout-container .purchase-plan .purchase-name p{
  position:relative;
  top:-14px;
  left:0
}
.checkout-container .purchase-plan .purchase-features ul li{
  margin-left:20px;
  padding:5px 0
}
.checkout-container .purchase-plan .purchase-features svg{
  width:15px;
  height:15px;
  position:relative;
  top:3px;
  left:-5px;
  fill:#104acc
}
.checkout-container .configuration{
  border:1px solid #edeef0;
  margin:32px 18px 20px;
  padding:12px 36px;
  border-radius:4px;
  background-color:#fff
}
.checkout-container .configuration .config-application-name h3{
  margin-bottom:10px
}
.checkout-container .configuration .config-application-name .notification.error{
  margin-top:0;
  padding:10px 15px;
  display:none;
  font-size:14px
}
.checkout-container .configuration .config-application-name input{
  margin-bottom:0
}
.checkout-container .configuration .config-application-name.error input[type=text]{
  margin-bottom:0;
  border-color:#e87677
}
.checkout-container .configuration .config-application-name.error .notification.error{
  display:block
}
.checkout-container .configuration .config-developers-count .selectbox{
  margin-bottom:0
}
.checkout-container .configuration .config-developers-count .hint,.checkout-container .configuration .config-developers-count .hint a{
  margin-top:10px;
  font-size:14px
}
.checkout-container .configuration .config-consent{
  margin-top:10px;
  padding-bottom:20px
}
.checkout-container .configuration .config-consent label{
  width:90%;
  display:inline-block
}
.checkout-container .configuration .config-consent.error label,.checkout-container .configuration .config-consent.error label>a{
  color:#e87677
}
.checkout-container .configuration .config-consent input{
  position:relative;
  top:-48px;
  left:5px;
  margin-right:10px
}
@media only screen and (min-width:40.0625em){
  .checkout-container .configuration .config-consent input{
      position:relative;
      top:-24px;
      left:5px
  }
}
.checkout-container .configuration .config-summary{
  border-top:1px solid #edeef0;
  padding-top:20px
}
.checkout-container .configuration .config-summary h3.total,.checkout-container .configuration .config-summary p.tax-info{
  margin:0
}
.checkout-container .configuration .config-summary h3.total,.checkout-container .configuration .config-summary h3.total>span{
  font-size:20px;
  font-weight:700
}
.checkout-container .configuration .config-summary h3.total.original-price,.checkout-container .configuration .config-summary h3.total>span.original-price{
  color:#8d8d8d;
  text-decoration:line-through
}
.checkout-container .configuration .config-summary p.tax-info{
  font-size:14px;
  color:#a1a7bd
}
.checkout-container .configuration .config-summary button{
  width:100%
}
@media only screen and (min-width:40.0625em){
  .checkout-container .configuration .config-summary button{
      width:auto
  }
}
.resellers li{
  padding-bottom:30px
}
.resellers li h3{
  font-size:18px
}
.resellers li p{
  margin:0;
  line-height:2
}
.our-mission{
  margin:20px 0 150px
}
.our-mission p{
  font-size:15px;
  text-align:justify;
  font-family:Roboto,Helvetica,Arial,sans-serif
}
.team-member .social-links{
  margin-bottom:45px;
  padding-top:6px;
  display:none;
  text-align:center
}
@media only screen and (min-width:64.0625em){
  .team-member .social-links{
      display:block
  }
}
.team-member .social-links a{
  width:17%;
  height:10vh;
  display:inline-block;
  margin:0 2%
}
@media only screen and (min-width:64.0625em){
  .team-member .social-links a{
      width:24px;
      height:24px;
      margin:0 14px
  }
}
.team-member .social-links a svg{
  -webkit-transition:fill .3s ease;
  transition:fill .3s ease;
  width:100%;
  height:100%;
  fill:#bdbdbd
}
.team-member .social-links a svg:hover{
  fill:#969696
}
.team h2{
  font-weight:400;
  text-align:left;
  margin:0;
  padding:0
}
@media only screen and (min-width:64.0625em){
  .team h2{
      text-align:center
  }
}
.team .subhead{
  padding:0;
  margin:0 0 40px 0;
  text-align:left;
  font-family:Roboto,Helvetica,Arial,sans-serif;
  font-weight:300
}
@media only screen and (min-width:64.0625em){
  .team .subhead{
      text-align:center
  }
}
@media only screen and (min-width:64.0625em){
  .team .headline{
      margin-bottom:10px
  }
}
.team-member{
  text-align:center
}
.team-member h4{
  margin:20px 0 50px;
  font-size:18px;
  line-height:1.3;
  text-align:center
}
@media only screen and (min-width:64.0625em){
  .team-member h4{
      font-size:20px
  }
}
.team-member h4 small{
  font-size:16px;
  font-weight:200
}
.team-member p{
  width:70%;
  margin:0 auto 12px;
  color:#363636;
  font-size:15px
}
.team-member img{
  width:200px;
  border-radius:100%
}
.team .testimony{
  border-top:1px solid #f7f7f7;
  padding:28px 0 60px;
  margin-top:2px
}
@media only screen and (min-width:64.0625em){
  .team .testimony .element-center{
      margin-left:8px
  }
}
.team .testimony blockquote,.team .testimony img{
  display:inline-block
}
.team .testimony img{
  border-radius:100%;
  max-width:100px;
  margin-top:20px
}
.team .testimony h5{
  margin:12px 0
}
.team .testimony a{
  font-size:inherit
}
.error .headline{
  margin:25% 0 25%;
  border-bottom:none
}
.animated{
  -webkit-animation-duration:1s;
  animation-duration:1s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both
}
.animated.infinite{
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite
}
.animated.hinge{
  -webkit-animation-duration:2s;
  animation-duration:2s
}
.animated.bounceIn,.animated.bounceOut,.animated.flipOutX,.animated.flipOutY{
  -webkit-animation-duration:.75s;
  animation-duration:.75s
}
@-webkit-keyframes bounce{
  20%,53%,80%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1);
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  40%,43%{
      -webkit-animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      -webkit-transform:translate3d(0,-30px,0);
      transform:translate3d(0,-30px,0)
  }
  70%{
      -webkit-animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      -webkit-transform:translate3d(0,-15px,0);
      transform:translate3d(0,-15px,0)
  }
  90%{
      -webkit-transform:translate3d(0,-4px,0);
      transform:translate3d(0,-4px,0)
  }
}
@keyframes bounce{
  20%,53%,80%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1);
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  40%,43%{
      -webkit-animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      -webkit-transform:translate3d(0,-30px,0);
      transform:translate3d(0,-30px,0)
  }
  70%{
      -webkit-animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      animation-timing-function:cubic-bezier(.755,.05,.855,.06);
      -webkit-transform:translate3d(0,-15px,0);
      transform:translate3d(0,-15px,0)
  }
  90%{
      -webkit-transform:translate3d(0,-4px,0);
      transform:translate3d(0,-4px,0)
  }
}
.bounce{
  -webkit-animation-name:bounce;
  animation-name:bounce;
  -webkit-transform-origin:center bottom;
  transform-origin:center bottom
}
@-webkit-keyframes flash{
  50%,from,to{
      opacity:1
  }
  25%,75%{
      opacity:0
  }
}
@keyframes flash{
  50%,from,to{
      opacity:1
  }
  25%,75%{
      opacity:0
  }
}
.flash{
  -webkit-animation-name:flash;
  animation-name:flash
}
@-webkit-keyframes pulse{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  50%{
      -webkit-transform:scale3d(1.05,1.05,1.05);
      transform:scale3d(1.05,1.05,1.05)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
@keyframes pulse{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  50%{
      -webkit-transform:scale3d(1.05,1.05,1.05);
      transform:scale3d(1.05,1.05,1.05)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
.pulse{
  -webkit-animation-name:pulse;
  animation-name:pulse
}
@-webkit-keyframes rubberBand{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  30%{
      -webkit-transform:scale3d(1.25,.75,1);
      transform:scale3d(1.25,.75,1)
  }
  40%{
      -webkit-transform:scale3d(.75,1.25,1);
      transform:scale3d(.75,1.25,1)
  }
  50%{
      -webkit-transform:scale3d(1.15,.85,1);
      transform:scale3d(1.15,.85,1)
  }
  65%{
      -webkit-transform:scale3d(.95,1.05,1);
      transform:scale3d(.95,1.05,1)
  }
  75%{
      -webkit-transform:scale3d(1.05,.95,1);
      transform:scale3d(1.05,.95,1)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
@keyframes rubberBand{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  30%{
      -webkit-transform:scale3d(1.25,.75,1);
      transform:scale3d(1.25,.75,1)
  }
  40%{
      -webkit-transform:scale3d(.75,1.25,1);
      transform:scale3d(.75,1.25,1)
  }
  50%{
      -webkit-transform:scale3d(1.15,.85,1);
      transform:scale3d(1.15,.85,1)
  }
  65%{
      -webkit-transform:scale3d(.95,1.05,1);
      transform:scale3d(.95,1.05,1)
  }
  75%{
      -webkit-transform:scale3d(1.05,.95,1);
      transform:scale3d(1.05,.95,1)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
.rubberBand{
  -webkit-animation-name:rubberBand;
  animation-name:rubberBand
}
@-webkit-keyframes shake{
  from,to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  10%,30%,50%,70%,90%{
      -webkit-transform:translate3d(-10px,0,0);
      transform:translate3d(-10px,0,0)
  }
  20%,40%,60%,80%{
      -webkit-transform:translate3d(10px,0,0);
      transform:translate3d(10px,0,0)
  }
}
@keyframes shake{
  from,to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  10%,30%,50%,70%,90%{
      -webkit-transform:translate3d(-10px,0,0);
      transform:translate3d(-10px,0,0)
  }
  20%,40%,60%,80%{
      -webkit-transform:translate3d(10px,0,0);
      transform:translate3d(10px,0,0)
  }
}
.shake{
  -webkit-animation-name:shake;
  animation-name:shake
}
@-webkit-keyframes headShake{
  0%{
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
  6.5%{
      -webkit-transform:translateX(-6px) rotateY(-9deg);
      transform:translateX(-6px) rotateY(-9deg)
  }
  18.5%{
      -webkit-transform:translateX(5px) rotateY(7deg);
      transform:translateX(5px) rotateY(7deg)
  }
  31.5%{
      -webkit-transform:translateX(-3px) rotateY(-5deg);
      transform:translateX(-3px) rotateY(-5deg)
  }
  43.5%{
      -webkit-transform:translateX(2px) rotateY(3deg);
      transform:translateX(2px) rotateY(3deg)
  }
  50%{
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}
@keyframes headShake{
  0%{
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
  6.5%{
      -webkit-transform:translateX(-6px) rotateY(-9deg);
      transform:translateX(-6px) rotateY(-9deg)
  }
  18.5%{
      -webkit-transform:translateX(5px) rotateY(7deg);
      transform:translateX(5px) rotateY(7deg)
  }
  31.5%{
      -webkit-transform:translateX(-3px) rotateY(-5deg);
      transform:translateX(-3px) rotateY(-5deg)
  }
  43.5%{
      -webkit-transform:translateX(2px) rotateY(3deg);
      transform:translateX(2px) rotateY(3deg)
  }
  50%{
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}
.headShake{
  -webkit-animation-timing-function:ease-in-out;
  animation-timing-function:ease-in-out;
  -webkit-animation-name:headShake;
  animation-name:headShake
}
@-webkit-keyframes swing{
  20%{
      -webkit-transform:rotate3d(0,0,1,15deg);
      transform:rotate3d(0,0,1,15deg)
  }
  40%{
      -webkit-transform:rotate3d(0,0,1,-10deg);
      transform:rotate3d(0,0,1,-10deg)
  }
  60%{
      -webkit-transform:rotate3d(0,0,1,5deg);
      transform:rotate3d(0,0,1,5deg)
  }
  80%{
      -webkit-transform:rotate3d(0,0,1,-5deg);
      transform:rotate3d(0,0,1,-5deg)
  }
  to{
      -webkit-transform:rotate3d(0,0,1,0deg);
      transform:rotate3d(0,0,1,0deg)
  }
}
@keyframes swing{
  20%{
      -webkit-transform:rotate3d(0,0,1,15deg);
      transform:rotate3d(0,0,1,15deg)
  }
  40%{
      -webkit-transform:rotate3d(0,0,1,-10deg);
      transform:rotate3d(0,0,1,-10deg)
  }
  60%{
      -webkit-transform:rotate3d(0,0,1,5deg);
      transform:rotate3d(0,0,1,5deg)
  }
  80%{
      -webkit-transform:rotate3d(0,0,1,-5deg);
      transform:rotate3d(0,0,1,-5deg)
  }
  to{
      -webkit-transform:rotate3d(0,0,1,0deg);
      transform:rotate3d(0,0,1,0deg)
  }
}
.swing{
  -webkit-transform-origin:top center;
  transform-origin:top center;
  -webkit-animation-name:swing;
  animation-name:swing
}
@-webkit-keyframes tada{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  10%,20%{
      -webkit-transform:scale3d(.9,.9,.9) rotate3d(0,0,1,-3deg);
      transform:scale3d(.9,.9,.9) rotate3d(0,0,1,-3deg)
  }
  30%,50%,70%,90%{
      -webkit-transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,3deg);
      transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,3deg)
  }
  40%,60%,80%{
      -webkit-transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,-3deg);
      transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,-3deg)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
@keyframes tada{
  from{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
  10%,20%{
      -webkit-transform:scale3d(.9,.9,.9) rotate3d(0,0,1,-3deg);
      transform:scale3d(.9,.9,.9) rotate3d(0,0,1,-3deg)
  }
  30%,50%,70%,90%{
      -webkit-transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,3deg);
      transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,3deg)
  }
  40%,60%,80%{
      -webkit-transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,-3deg);
      transform:scale3d(1.1,1.1,1.1) rotate3d(0,0,1,-3deg)
  }
  to{
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
.tada{
  -webkit-animation-name:tada;
  animation-name:tada
}
@-webkit-keyframes wobble{
  from{
      -webkit-transform:none;
      transform:none
  }
  15%{
      -webkit-transform:translate3d(-25%,0,0) rotate3d(0,0,1,-5deg);
      transform:translate3d(-25%,0,0) rotate3d(0,0,1,-5deg)
  }
  30%{
      -webkit-transform:translate3d(20%,0,0) rotate3d(0,0,1,3deg);
      transform:translate3d(20%,0,0) rotate3d(0,0,1,3deg)
  }
  45%{
      -webkit-transform:translate3d(-15%,0,0) rotate3d(0,0,1,-3deg);
      transform:translate3d(-15%,0,0) rotate3d(0,0,1,-3deg)
  }
  60%{
      -webkit-transform:translate3d(10%,0,0) rotate3d(0,0,1,2deg);
      transform:translate3d(10%,0,0) rotate3d(0,0,1,2deg)
  }
  75%{
      -webkit-transform:translate3d(-5%,0,0) rotate3d(0,0,1,-1deg);
      transform:translate3d(-5%,0,0) rotate3d(0,0,1,-1deg)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
@keyframes wobble{
  from{
      -webkit-transform:none;
      transform:none
  }
  15%{
      -webkit-transform:translate3d(-25%,0,0) rotate3d(0,0,1,-5deg);
      transform:translate3d(-25%,0,0) rotate3d(0,0,1,-5deg)
  }
  30%{
      -webkit-transform:translate3d(20%,0,0) rotate3d(0,0,1,3deg);
      transform:translate3d(20%,0,0) rotate3d(0,0,1,3deg)
  }
  45%{
      -webkit-transform:translate3d(-15%,0,0) rotate3d(0,0,1,-3deg);
      transform:translate3d(-15%,0,0) rotate3d(0,0,1,-3deg)
  }
  60%{
      -webkit-transform:translate3d(10%,0,0) rotate3d(0,0,1,2deg);
      transform:translate3d(10%,0,0) rotate3d(0,0,1,2deg)
  }
  75%{
      -webkit-transform:translate3d(-5%,0,0) rotate3d(0,0,1,-1deg);
      transform:translate3d(-5%,0,0) rotate3d(0,0,1,-1deg)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
.wobble{
  -webkit-animation-name:wobble;
  animation-name:wobble
}
@-webkit-keyframes jello{
  11.1%,from,to{
      -webkit-transform:none;
      transform:none
  }
  22.2%{
      -webkit-transform:skewX(-12.5deg) skewY(-12.5deg);
      transform:skewX(-12.5deg) skewY(-12.5deg)
  }
  33.3%{
      -webkit-transform:skewX(6.25deg) skewY(6.25deg);
      transform:skewX(6.25deg) skewY(6.25deg)
  }
  44.4%{
      -webkit-transform:skewX(-3.125deg) skewY(-3.125deg);
      transform:skewX(-3.125deg) skewY(-3.125deg)
  }
  55.5%{
      -webkit-transform:skewX(1.5625deg) skewY(1.5625deg);
      transform:skewX(1.5625deg) skewY(1.5625deg)
  }
  66.6%{
      -webkit-transform:skewX(-.78125deg) skewY(-.78125deg);
      transform:skewX(-.78125deg) skewY(-.78125deg)
  }
  77.7%{
      -webkit-transform:skewX(.39062deg) skewY(.39062deg);
      transform:skewX(.39062deg) skewY(.39062deg)
  }
  88.8%{
      -webkit-transform:skewX(-.19531deg) skewY(-.19531deg);
      transform:skewX(-.19531deg) skewY(-.19531deg)
  }
}
@keyframes jello{
  11.1%,from,to{
      -webkit-transform:none;
      transform:none
  }
  22.2%{
      -webkit-transform:skewX(-12.5deg) skewY(-12.5deg);
      transform:skewX(-12.5deg) skewY(-12.5deg)
  }
  33.3%{
      -webkit-transform:skewX(6.25deg) skewY(6.25deg);
      transform:skewX(6.25deg) skewY(6.25deg)
  }
  44.4%{
      -webkit-transform:skewX(-3.125deg) skewY(-3.125deg);
      transform:skewX(-3.125deg) skewY(-3.125deg)
  }
  55.5%{
      -webkit-transform:skewX(1.5625deg) skewY(1.5625deg);
      transform:skewX(1.5625deg) skewY(1.5625deg)
  }
  66.6%{
      -webkit-transform:skewX(-.78125deg) skewY(-.78125deg);
      transform:skewX(-.78125deg) skewY(-.78125deg)
  }
  77.7%{
      -webkit-transform:skewX(.39062deg) skewY(.39062deg);
      transform:skewX(.39062deg) skewY(.39062deg)
  }
  88.8%{
      -webkit-transform:skewX(-.19531deg) skewY(-.19531deg);
      transform:skewX(-.19531deg) skewY(-.19531deg)
  }
}
.jello{
  -webkit-animation-name:jello;
  animation-name:jello;
  -webkit-transform-origin:center;
  transform-origin:center
}
@-webkit-keyframes bounceIn{
  20%,40%,60%,80%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  20%{
      -webkit-transform:scale3d(1.1,1.1,1.1);
      transform:scale3d(1.1,1.1,1.1)
  }
  40%{
      -webkit-transform:scale3d(.9,.9,.9);
      transform:scale3d(.9,.9,.9)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(1.03,1.03,1.03);
      transform:scale3d(1.03,1.03,1.03)
  }
  80%{
      -webkit-transform:scale3d(.97,.97,.97);
      transform:scale3d(.97,.97,.97)
  }
  to{
      opacity:1;
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
@keyframes bounceIn{
  20%,40%,60%,80%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  20%{
      -webkit-transform:scale3d(1.1,1.1,1.1);
      transform:scale3d(1.1,1.1,1.1)
  }
  40%{
      -webkit-transform:scale3d(.9,.9,.9);
      transform:scale3d(.9,.9,.9)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(1.03,1.03,1.03);
      transform:scale3d(1.03,1.03,1.03)
  }
  80%{
      -webkit-transform:scale3d(.97,.97,.97);
      transform:scale3d(.97,.97,.97)
  }
  to{
      opacity:1;
      -webkit-transform:scale3d(1,1,1);
      transform:scale3d(1,1,1)
  }
}
.bounceIn{
  -webkit-animation-name:bounceIn;
  animation-name:bounceIn
}
@-webkit-keyframes bounceInDown{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(0,-3000px,0);
      transform:translate3d(0,-3000px,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(0,25px,0);
      transform:translate3d(0,25px,0)
  }
  75%{
      -webkit-transform:translate3d(0,-10px,0);
      transform:translate3d(0,-10px,0)
  }
  90%{
      -webkit-transform:translate3d(0,5px,0);
      transform:translate3d(0,5px,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
@keyframes bounceInDown{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(0,-3000px,0);
      transform:translate3d(0,-3000px,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(0,25px,0);
      transform:translate3d(0,25px,0)
  }
  75%{
      -webkit-transform:translate3d(0,-10px,0);
      transform:translate3d(0,-10px,0)
  }
  90%{
      -webkit-transform:translate3d(0,5px,0);
      transform:translate3d(0,5px,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
.bounceInDown{
  -webkit-animation-name:bounceInDown;
  animation-name:bounceInDown
}
@-webkit-keyframes bounceInLeft{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(-3000px,0,0);
      transform:translate3d(-3000px,0,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(25px,0,0);
      transform:translate3d(25px,0,0)
  }
  75%{
      -webkit-transform:translate3d(-10px,0,0);
      transform:translate3d(-10px,0,0)
  }
  90%{
      -webkit-transform:translate3d(5px,0,0);
      transform:translate3d(5px,0,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
@keyframes bounceInLeft{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  0%{
      opacity:0;
      -webkit-transform:translate3d(-3000px,0,0);
      transform:translate3d(-3000px,0,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(25px,0,0);
      transform:translate3d(25px,0,0)
  }
  75%{
      -webkit-transform:translate3d(-10px,0,0);
      transform:translate3d(-10px,0,0)
  }
  90%{
      -webkit-transform:translate3d(5px,0,0);
      transform:translate3d(5px,0,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
.bounceInLeft{
  -webkit-animation-name:bounceInLeft;
  animation-name:bounceInLeft
}
@-webkit-keyframes bounceInRight{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  from{
      opacity:0;
      -webkit-transform:translate3d(3000px,0,0);
      transform:translate3d(3000px,0,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(-25px,0,0);
      transform:translate3d(-25px,0,0)
  }
  75%{
      -webkit-transform:translate3d(10px,0,0);
      transform:translate3d(10px,0,0)
  }
  90%{
      -webkit-transform:translate3d(-5px,0,0);
      transform:translate3d(-5px,0,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
@keyframes bounceInRight{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  from{
      opacity:0;
      -webkit-transform:translate3d(3000px,0,0);
      transform:translate3d(3000px,0,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(-25px,0,0);
      transform:translate3d(-25px,0,0)
  }
  75%{
      -webkit-transform:translate3d(10px,0,0);
      transform:translate3d(10px,0,0)
  }
  90%{
      -webkit-transform:translate3d(-5px,0,0);
      transform:translate3d(-5px,0,0)
  }
  to{
      -webkit-transform:none;
      transform:none
  }
}
.bounceInRight{
  -webkit-animation-name:bounceInRight;
  animation-name:bounceInRight
}
@-webkit-keyframes bounceInUp{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  from{
      opacity:0;
      -webkit-transform:translate3d(0,3000px,0);
      transform:translate3d(0,3000px,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(0,-20px,0);
      transform:translate3d(0,-20px,0)
  }
  75%{
      -webkit-transform:translate3d(0,10px,0);
      transform:translate3d(0,10px,0)
  }
  90%{
      -webkit-transform:translate3d(0,-5px,0);
      transform:translate3d(0,-5px,0)
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
@keyframes bounceInUp{
  60%,75%,90%,from,to{
      -webkit-animation-timing-function:cubic-bezier(.215,.61,.355,1);
      animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  from{
      opacity:0;
      -webkit-transform:translate3d(0,3000px,0);
      transform:translate3d(0,3000px,0)
  }
  60%{
      opacity:1;
      -webkit-transform:translate3d(0,-20px,0);
      transform:translate3d(0,-20px,0)
  }
  75%{
      -webkit-transform:translate3d(0,10px,0);
      transform:translate3d(0,10px,0)
  }
  90%{
      -webkit-transform:translate3d(0,-5px,0);
      transform:translate3d(0,-5px,0)
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
.bounceInUp{
  -webkit-animation-name:bounceInUp;
  animation-name:bounceInUp
}
@-webkit-keyframes bounceOut{
  20%{
      -webkit-transform:scale3d(.9,.9,.9);
      transform:scale3d(.9,.9,.9)
  }
  50%,55%{
      opacity:1;
      -webkit-transform:scale3d(1.1,1.1,1.1);
      transform:scale3d(1.1,1.1,1.1)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
}
@keyframes bounceOut{
  20%{
      -webkit-transform:scale3d(.9,.9,.9);
      transform:scale3d(.9,.9,.9)
  }
  50%,55%{
      opacity:1;
      -webkit-transform:scale3d(1.1,1.1,1.1);
      transform:scale3d(1.1,1.1,1.1)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
}
.bounceOut{
  -webkit-animation-name:bounceOut;
  animation-name:bounceOut
}
@-webkit-keyframes bounceOutDown{
  20%{
      -webkit-transform:translate3d(0,10px,0);
      transform:translate3d(0,10px,0)
  }
  40%,45%{
      opacity:1;
      -webkit-transform:translate3d(0,-20px,0);
      transform:translate3d(0,-20px,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
}
@keyframes bounceOutDown{
  20%{
      -webkit-transform:translate3d(0,10px,0);
      transform:translate3d(0,10px,0)
  }
  40%,45%{
      opacity:1;
      -webkit-transform:translate3d(0,-20px,0);
      transform:translate3d(0,-20px,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
}
.bounceOutDown{
  -webkit-animation-name:bounceOutDown;
  animation-name:bounceOutDown
}
@-webkit-keyframes bounceOutLeft{
  20%{
      opacity:1;
      -webkit-transform:translate3d(20px,0,0);
      transform:translate3d(20px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
}
@keyframes bounceOutLeft{
  20%{
      opacity:1;
      -webkit-transform:translate3d(20px,0,0);
      transform:translate3d(20px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
}
.bounceOutLeft{
  -webkit-animation-name:bounceOutLeft;
  animation-name:bounceOutLeft
}
@-webkit-keyframes bounceOutRight{
  20%{
      opacity:1;
      -webkit-transform:translate3d(-20px,0,0);
      transform:translate3d(-20px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
}
@keyframes bounceOutRight{
  20%{
      opacity:1;
      -webkit-transform:translate3d(-20px,0,0);
      transform:translate3d(-20px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
}
.bounceOutRight{
  -webkit-animation-name:bounceOutRight;
  animation-name:bounceOutRight
}
@-webkit-keyframes bounceOutUp{
  20%{
      -webkit-transform:translate3d(0,-10px,0);
      transform:translate3d(0,-10px,0)
  }
  40%,45%{
      opacity:1;
      -webkit-transform:translate3d(0,20px,0);
      transform:translate3d(0,20px,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
}
@keyframes bounceOutUp{
  20%{
      -webkit-transform:translate3d(0,-10px,0);
      transform:translate3d(0,-10px,0)
  }
  40%,45%{
      opacity:1;
      -webkit-transform:translate3d(0,20px,0);
      transform:translate3d(0,20px,0)
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
}
.bounceOutUp{
  -webkit-animation-name:bounceOutUp;
  animation-name:bounceOutUp
}
@-webkit-keyframes fadeIn{
  from{
      opacity:0
  }
  to{
      opacity:1
  }
}
@keyframes fadeIn{
  from{
      opacity:0
  }
  to{
      opacity:1
  }
}
.fadeIn{
  -webkit-animation-name:fadeIn;
  animation-name:fadeIn
}
@-webkit-keyframes fadeInDown{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInDown{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInDown{
  -webkit-animation-name:fadeInDown;
  animation-name:fadeInDown
}
@-webkit-keyframes fadeInDownBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInDownBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInDownBig{
  -webkit-animation-name:fadeInDownBig;
  animation-name:fadeInDownBig
}
@-webkit-keyframes fadeInLeft{
  from{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInLeft{
  from{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInLeft{
  -webkit-animation-name:fadeInLeft;
  animation-name:fadeInLeft
}
@-webkit-keyframes fadeInLeftBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInLeftBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInLeftBig{
  -webkit-animation-name:fadeInLeftBig;
  animation-name:fadeInLeftBig
}
@-webkit-keyframes fadeInRight{
  from{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInRight{
  from{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInRight{
  -webkit-animation-name:fadeInRight;
  animation-name:fadeInRight
}
@-webkit-keyframes fadeInRightBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInRightBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInRightBig{
  -webkit-animation-name:fadeInRightBig;
  animation-name:fadeInRightBig
}
@-webkit-keyframes fadeInUp{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInUp{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInUp{
  -webkit-animation-name:fadeInUp;
  animation-name:fadeInUp
}
@-webkit-keyframes fadeInUpBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes fadeInUpBig{
  from{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.fadeInUpBig{
  -webkit-animation-name:fadeInUpBig;
  animation-name:fadeInUpBig
}
@-webkit-keyframes fadeOut{
  from{
      opacity:1
  }
  to{
      opacity:0
  }
}
@keyframes fadeOut{
  from{
      opacity:1
  }
  to{
      opacity:0
  }
}
.fadeOut{
  -webkit-animation-name:fadeOut;
  animation-name:fadeOut
}
@-webkit-keyframes fadeOutDown{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
}
@keyframes fadeOutDown{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
}
.fadeOutDown{
  -webkit-animation-name:fadeOutDown;
  animation-name:fadeOutDown
}
@-webkit-keyframes fadeOutDownBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
}
@keyframes fadeOutDownBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,2000px,0);
      transform:translate3d(0,2000px,0)
  }
}
.fadeOutDownBig{
  -webkit-animation-name:fadeOutDownBig;
  animation-name:fadeOutDownBig
}
@-webkit-keyframes fadeOutLeft{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
}
@keyframes fadeOutLeft{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
}
.fadeOutLeft{
  -webkit-animation-name:fadeOutLeft;
  animation-name:fadeOutLeft
}
@-webkit-keyframes fadeOutLeftBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
}
@keyframes fadeOutLeftBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(-2000px,0,0);
      transform:translate3d(-2000px,0,0)
  }
}
.fadeOutLeftBig{
  -webkit-animation-name:fadeOutLeftBig;
  animation-name:fadeOutLeftBig
}
@-webkit-keyframes fadeOutRight{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
}
@keyframes fadeOutRight{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
}
.fadeOutRight{
  -webkit-animation-name:fadeOutRight;
  animation-name:fadeOutRight
}
@-webkit-keyframes fadeOutRightBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
}
@keyframes fadeOutRightBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(2000px,0,0);
      transform:translate3d(2000px,0,0)
  }
}
.fadeOutRightBig{
  -webkit-animation-name:fadeOutRightBig;
  animation-name:fadeOutRightBig
}
@-webkit-keyframes fadeOutUp{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
}
@keyframes fadeOutUp{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
}
.fadeOutUp{
  -webkit-animation-name:fadeOutUp;
  animation-name:fadeOutUp
}
@-webkit-keyframes fadeOutUpBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
}
@keyframes fadeOutUpBig{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(0,-2000px,0);
      transform:translate3d(0,-2000px,0)
  }
}
.fadeOutUpBig{
  -webkit-animation-name:fadeOutUpBig;
  animation-name:fadeOutUpBig
}
@-webkit-keyframes flip{
  from{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-360deg);
      transform:perspective(400px) rotate3d(0,1,0,-360deg);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
  }
  40%{
      -webkit-transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-190deg);
      transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-190deg);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
  }
  50%{
      -webkit-transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-170deg);
      transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-170deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  80%{
      -webkit-transform:perspective(400px) scale3d(.95,.95,.95);
      transform:perspective(400px) scale3d(.95,.95,.95);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
}
@keyframes flip{
  from{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-360deg);
      transform:perspective(400px) rotate3d(0,1,0,-360deg);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
  }
  40%{
      -webkit-transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-190deg);
      transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-190deg);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
  }
  50%{
      -webkit-transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-170deg);
      transform:perspective(400px) translate3d(0,0,150px) rotate3d(0,1,0,-170deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  80%{
      -webkit-transform:perspective(400px) scale3d(.95,.95,.95);
      transform:perspective(400px) scale3d(.95,.95,.95);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
}
.animated.flip{
  -webkit-backface-visibility:visible;
  backface-visibility:visible;
  -webkit-animation-name:flip;
  animation-name:flip
}
@-webkit-keyframes flipInX{
  from{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,90deg);
      transform:perspective(400px) rotate3d(1,0,0,90deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in;
      opacity:0
  }
  40%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-20deg);
      transform:perspective(400px) rotate3d(1,0,0,-20deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  60%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,10deg);
      transform:perspective(400px) rotate3d(1,0,0,10deg);
      opacity:1
  }
  80%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-5deg);
      transform:perspective(400px) rotate3d(1,0,0,-5deg)
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
}
@keyframes flipInX{
  from{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,90deg);
      transform:perspective(400px) rotate3d(1,0,0,90deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in;
      opacity:0
  }
  40%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-20deg);
      transform:perspective(400px) rotate3d(1,0,0,-20deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  60%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,10deg);
      transform:perspective(400px) rotate3d(1,0,0,10deg);
      opacity:1
  }
  80%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-5deg);
      transform:perspective(400px) rotate3d(1,0,0,-5deg)
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
}
.flipInX{
  -webkit-backface-visibility:visible!important;
  backface-visibility:visible!important;
  -webkit-animation-name:flipInX;
  animation-name:flipInX
}
@-webkit-keyframes flipInY{
  from{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,90deg);
      transform:perspective(400px) rotate3d(0,1,0,90deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in;
      opacity:0
  }
  40%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-20deg);
      transform:perspective(400px) rotate3d(0,1,0,-20deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  60%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,10deg);
      transform:perspective(400px) rotate3d(0,1,0,10deg);
      opacity:1
  }
  80%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-5deg);
      transform:perspective(400px) rotate3d(0,1,0,-5deg)
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
}
@keyframes flipInY{
  from{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,90deg);
      transform:perspective(400px) rotate3d(0,1,0,90deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in;
      opacity:0
  }
  40%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-20deg);
      transform:perspective(400px) rotate3d(0,1,0,-20deg);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
  }
  60%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,10deg);
      transform:perspective(400px) rotate3d(0,1,0,10deg);
      opacity:1
  }
  80%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-5deg);
      transform:perspective(400px) rotate3d(0,1,0,-5deg)
  }
  to{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
}
.flipInY{
  -webkit-backface-visibility:visible!important;
  backface-visibility:visible!important;
  -webkit-animation-name:flipInY;
  animation-name:flipInY
}
@-webkit-keyframes flipOutX{
  from{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
  30%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-20deg);
      transform:perspective(400px) rotate3d(1,0,0,-20deg);
      opacity:1
  }
  to{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,90deg);
      transform:perspective(400px) rotate3d(1,0,0,90deg);
      opacity:0
  }
}
@keyframes flipOutX{
  from{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
  30%{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,-20deg);
      transform:perspective(400px) rotate3d(1,0,0,-20deg);
      opacity:1
  }
  to{
      -webkit-transform:perspective(400px) rotate3d(1,0,0,90deg);
      transform:perspective(400px) rotate3d(1,0,0,90deg);
      opacity:0
  }
}
.flipOutX{
  -webkit-animation-name:flipOutX;
  animation-name:flipOutX;
  -webkit-backface-visibility:visible!important;
  backface-visibility:visible!important
}
@-webkit-keyframes flipOutY{
  from{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
  30%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-15deg);
      transform:perspective(400px) rotate3d(0,1,0,-15deg);
      opacity:1
  }
  to{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,90deg);
      transform:perspective(400px) rotate3d(0,1,0,90deg);
      opacity:0
  }
}
@keyframes flipOutY{
  from{
      -webkit-transform:perspective(400px);
      transform:perspective(400px)
  }
  30%{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,-15deg);
      transform:perspective(400px) rotate3d(0,1,0,-15deg);
      opacity:1
  }
  to{
      -webkit-transform:perspective(400px) rotate3d(0,1,0,90deg);
      transform:perspective(400px) rotate3d(0,1,0,90deg);
      opacity:0
  }
}
.flipOutY{
  -webkit-backface-visibility:visible!important;
  backface-visibility:visible!important;
  -webkit-animation-name:flipOutY;
  animation-name:flipOutY
}
@-webkit-keyframes lightSpeedIn{
  from{
      -webkit-transform:translate3d(100%,0,0) skewX(-30deg);
      transform:translate3d(100%,0,0) skewX(-30deg);
      opacity:0
  }
  60%{
      -webkit-transform:skewX(20deg);
      transform:skewX(20deg);
      opacity:1
  }
  80%{
      -webkit-transform:skewX(-5deg);
      transform:skewX(-5deg);
      opacity:1
  }
  to{
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes lightSpeedIn{
  from{
      -webkit-transform:translate3d(100%,0,0) skewX(-30deg);
      transform:translate3d(100%,0,0) skewX(-30deg);
      opacity:0
  }
  60%{
      -webkit-transform:skewX(20deg);
      transform:skewX(20deg);
      opacity:1
  }
  80%{
      -webkit-transform:skewX(-5deg);
      transform:skewX(-5deg);
      opacity:1
  }
  to{
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.lightSpeedIn{
  -webkit-animation-name:lightSpeedIn;
  animation-name:lightSpeedIn;
  -webkit-animation-timing-function:ease-out;
  animation-timing-function:ease-out
}
@-webkit-keyframes lightSpeedOut{
  from{
      opacity:1
  }
  to{
      -webkit-transform:translate3d(100%,0,0) skewX(30deg);
      transform:translate3d(100%,0,0) skewX(30deg);
      opacity:0
  }
}
@keyframes lightSpeedOut{
  from{
      opacity:1
  }
  to{
      -webkit-transform:translate3d(100%,0,0) skewX(30deg);
      transform:translate3d(100%,0,0) skewX(30deg);
      opacity:0
  }
}
.lightSpeedOut{
  -webkit-animation-name:lightSpeedOut;
  animation-name:lightSpeedOut;
  -webkit-animation-timing-function:ease-in;
  animation-timing-function:ease-in
}
@-webkit-keyframes rotateIn{
  from{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:rotate3d(0,0,1,-200deg);
      transform:rotate3d(0,0,1,-200deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes rotateIn{
  from{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:rotate3d(0,0,1,-200deg);
      transform:rotate3d(0,0,1,-200deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.rotateIn{
  -webkit-animation-name:rotateIn;
  animation-name:rotateIn
}
@-webkit-keyframes rotateInDownLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes rotateInDownLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.rotateInDownLeft{
  -webkit-animation-name:rotateInDownLeft;
  animation-name:rotateInDownLeft
}
@-webkit-keyframes rotateInDownRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes rotateInDownRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.rotateInDownRight{
  -webkit-animation-name:rotateInDownRight;
  animation-name:rotateInDownRight
}
@-webkit-keyframes rotateInUpLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes rotateInUpLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.rotateInUpLeft{
  -webkit-animation-name:rotateInUpLeft;
  animation-name:rotateInUpLeft
}
@-webkit-keyframes rotateInUpRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,-90deg);
      transform:rotate3d(0,0,1,-90deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
@keyframes rotateInUpRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,-90deg);
      transform:rotate3d(0,0,1,-90deg);
      opacity:0
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:none;
      transform:none;
      opacity:1
  }
}
.rotateInUpRight{
  -webkit-animation-name:rotateInUpRight;
  animation-name:rotateInUpRight
}
@-webkit-keyframes rotateOut{
  from{
      -webkit-transform-origin:center;
      transform-origin:center;
      opacity:1
  }
  to{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:rotate3d(0,0,1,200deg);
      transform:rotate3d(0,0,1,200deg);
      opacity:0
  }
}
@keyframes rotateOut{
  from{
      -webkit-transform-origin:center;
      transform-origin:center;
      opacity:1
  }
  to{
      -webkit-transform-origin:center;
      transform-origin:center;
      -webkit-transform:rotate3d(0,0,1,200deg);
      transform:rotate3d(0,0,1,200deg);
      opacity:0
  }
}
.rotateOut{
  -webkit-animation-name:rotateOut;
  animation-name:rotateOut
}
@-webkit-keyframes rotateOutDownLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
}
@keyframes rotateOutDownLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,45deg);
      transform:rotate3d(0,0,1,45deg);
      opacity:0
  }
}
.rotateOutDownLeft{
  -webkit-animation-name:rotateOutDownLeft;
  animation-name:rotateOutDownLeft
}
@-webkit-keyframes rotateOutDownRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
}
@keyframes rotateOutDownRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
}
.rotateOutDownRight{
  -webkit-animation-name:rotateOutDownRight;
  animation-name:rotateOutDownRight
}
@-webkit-keyframes rotateOutUpLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
}
@keyframes rotateOutUpLeft{
  from{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:left bottom;
      transform-origin:left bottom;
      -webkit-transform:rotate3d(0,0,1,-45deg);
      transform:rotate3d(0,0,1,-45deg);
      opacity:0
  }
}
.rotateOutUpLeft{
  -webkit-animation-name:rotateOutUpLeft;
  animation-name:rotateOutUpLeft
}
@-webkit-keyframes rotateOutUpRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,90deg);
      transform:rotate3d(0,0,1,90deg);
      opacity:0
  }
}
@keyframes rotateOutUpRight{
  from{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      opacity:1
  }
  to{
      -webkit-transform-origin:right bottom;
      transform-origin:right bottom;
      -webkit-transform:rotate3d(0,0,1,90deg);
      transform:rotate3d(0,0,1,90deg);
      opacity:0
  }
}
.rotateOutUpRight{
  -webkit-animation-name:rotateOutUpRight;
  animation-name:rotateOutUpRight
}
@-webkit-keyframes hinge{
  0%{
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out
  }
  20%,60%{
      -webkit-transform:rotate3d(0,0,1,80deg);
      transform:rotate3d(0,0,1,80deg);
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out
  }
  40%,80%{
      -webkit-transform:rotate3d(0,0,1,60deg);
      transform:rotate3d(0,0,1,60deg);
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out;
      opacity:1
  }
  to{
      -webkit-transform:translate3d(0,700px,0);
      transform:translate3d(0,700px,0);
      opacity:0
  }
}
@keyframes hinge{
  0%{
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out
  }
  20%,60%{
      -webkit-transform:rotate3d(0,0,1,80deg);
      transform:rotate3d(0,0,1,80deg);
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out
  }
  40%,80%{
      -webkit-transform:rotate3d(0,0,1,60deg);
      transform:rotate3d(0,0,1,60deg);
      -webkit-transform-origin:top left;
      transform-origin:top left;
      -webkit-animation-timing-function:ease-in-out;
      animation-timing-function:ease-in-out;
      opacity:1
  }
  to{
      -webkit-transform:translate3d(0,700px,0);
      transform:translate3d(0,700px,0);
      opacity:0
  }
}
.hinge{
  -webkit-animation-name:hinge;
  animation-name:hinge
}
@-webkit-keyframes jackInTheBox{
  from{
      opacity:0;
      -webkit-transform:scale(.1) rotate(30deg);
      transform:scale(.1) rotate(30deg);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom
  }
  50%{
      -webkit-transform:rotate(-10deg);
      transform:rotate(-10deg)
  }
  70%{
      -webkit-transform:rotate(3deg);
      transform:rotate(3deg)
  }
  to{
      opacity:1;
      -webkit-transform:scale(1);
      transform:scale(1)
  }
}
@keyframes jackInTheBox{
  from{
      opacity:0;
      -webkit-transform:scale(.1) rotate(30deg);
      transform:scale(.1) rotate(30deg);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom
  }
  50%{
      -webkit-transform:rotate(-10deg);
      transform:rotate(-10deg)
  }
  70%{
      -webkit-transform:rotate(3deg);
      transform:rotate(3deg)
  }
  to{
      opacity:1;
      -webkit-transform:scale(1);
      transform:scale(1)
  }
}
.jackInTheBox{
  -webkit-animation-name:jackInTheBox;
  animation-name:jackInTheBox
}
@-webkit-keyframes rollIn{
  from{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0) rotate3d(0,0,1,-120deg);
      transform:translate3d(-100%,0,0) rotate3d(0,0,1,-120deg)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
@keyframes rollIn{
  from{
      opacity:0;
      -webkit-transform:translate3d(-100%,0,0) rotate3d(0,0,1,-120deg);
      transform:translate3d(-100%,0,0) rotate3d(0,0,1,-120deg)
  }
  to{
      opacity:1;
      -webkit-transform:none;
      transform:none
  }
}
.rollIn{
  -webkit-animation-name:rollIn;
  animation-name:rollIn
}
@-webkit-keyframes rollOut{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0) rotate3d(0,0,1,120deg);
      transform:translate3d(100%,0,0) rotate3d(0,0,1,120deg)
  }
}
@keyframes rollOut{
  from{
      opacity:1
  }
  to{
      opacity:0;
      -webkit-transform:translate3d(100%,0,0) rotate3d(0,0,1,120deg);
      transform:translate3d(100%,0,0) rotate3d(0,0,1,120deg)
  }
}
.rollOut{
  -webkit-animation-name:rollOut;
  animation-name:rollOut
}
@-webkit-keyframes zoomIn{
  from{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  50%{
      opacity:1
  }
}
@keyframes zoomIn{
  from{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  50%{
      opacity:1
  }
}
.zoomIn{
  -webkit-animation-name:zoomIn;
  animation-name:zoomIn
}
@-webkit-keyframes zoomInDown{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,-1000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,-1000px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomInDown{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,-1000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,-1000px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomInDown{
  -webkit-animation-name:zoomInDown;
  animation-name:zoomInDown
}
@-webkit-keyframes zoomInLeft{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(-1000px,0,0);
      transform:scale3d(.1,.1,.1) translate3d(-1000px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(10px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(10px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomInLeft{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(-1000px,0,0);
      transform:scale3d(.1,.1,.1) translate3d(-1000px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(10px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(10px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomInLeft{
  -webkit-animation-name:zoomInLeft;
  animation-name:zoomInLeft
}
@-webkit-keyframes zoomInRight{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(1000px,0,0);
      transform:scale3d(.1,.1,.1) translate3d(1000px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(-10px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(-10px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomInRight{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(1000px,0,0);
      transform:scale3d(.1,.1,.1) translate3d(1000px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(-10px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(-10px,0,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomInRight{
  -webkit-animation-name:zoomInRight;
  animation-name:zoomInRight
}
@-webkit-keyframes zoomInUp{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,1000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,1000px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomInUp{
  from{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,1000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,1000px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  60%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomInUp{
  -webkit-animation-name:zoomInUp;
  animation-name:zoomInUp
}
@-webkit-keyframes zoomOut{
  from{
      opacity:1
  }
  50%{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  to{
      opacity:0
  }
}
@keyframes zoomOut{
  from{
      opacity:1
  }
  50%{
      opacity:0;
      -webkit-transform:scale3d(.3,.3,.3);
      transform:scale3d(.3,.3,.3)
  }
  to{
      opacity:0
  }
}
.zoomOut{
  -webkit-animation-name:zoomOut;
  animation-name:zoomOut
}
@-webkit-keyframes zoomOutDown{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,2000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,2000px,0);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom;
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomOutDown{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,-60px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,2000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,2000px,0);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom;
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomOutDown{
  -webkit-animation-name:zoomOutDown;
  animation-name:zoomOutDown
}
@-webkit-keyframes zoomOutLeft{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(42px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(42px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:scale(.1) translate3d(-2000px,0,0);
      transform:scale(.1) translate3d(-2000px,0,0);
      -webkit-transform-origin:left center;
      transform-origin:left center
  }
}
@keyframes zoomOutLeft{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(42px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(42px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:scale(.1) translate3d(-2000px,0,0);
      transform:scale(.1) translate3d(-2000px,0,0);
      -webkit-transform-origin:left center;
      transform-origin:left center
  }
}
.zoomOutLeft{
  -webkit-animation-name:zoomOutLeft;
  animation-name:zoomOutLeft
}
@-webkit-keyframes zoomOutRight{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(-42px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(-42px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:scale(.1) translate3d(2000px,0,0);
      transform:scale(.1) translate3d(2000px,0,0);
      -webkit-transform-origin:right center;
      transform-origin:right center
  }
}
@keyframes zoomOutRight{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(-42px,0,0);
      transform:scale3d(.475,.475,.475) translate3d(-42px,0,0)
  }
  to{
      opacity:0;
      -webkit-transform:scale(.1) translate3d(2000px,0,0);
      transform:scale(.1) translate3d(2000px,0,0);
      -webkit-transform-origin:right center;
      transform-origin:right center
  }
}
.zoomOutRight{
  -webkit-animation-name:zoomOutRight;
  animation-name:zoomOutRight
}
@-webkit-keyframes zoomOutUp{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,-2000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,-2000px,0);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom;
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
@keyframes zoomOutUp{
  40%{
      opacity:1;
      -webkit-transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      transform:scale3d(.475,.475,.475) translate3d(0,60px,0);
      -webkit-animation-timing-function:cubic-bezier(.55,.055,.675,.19);
      animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  to{
      opacity:0;
      -webkit-transform:scale3d(.1,.1,.1) translate3d(0,-2000px,0);
      transform:scale3d(.1,.1,.1) translate3d(0,-2000px,0);
      -webkit-transform-origin:center bottom;
      transform-origin:center bottom;
      -webkit-animation-timing-function:cubic-bezier(.175,.885,.32,1);
      animation-timing-function:cubic-bezier(.175,.885,.32,1)
  }
}
.zoomOutUp{
  -webkit-animation-name:zoomOutUp;
  animation-name:zoomOutUp
}
@-webkit-keyframes slideInDown{
  from{
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
@keyframes slideInDown{
  from{
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
.slideInDown{
  -webkit-animation-name:slideInDown;
  animation-name:slideInDown
}
@-webkit-keyframes slideInLeft{
  from{
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
@keyframes slideInLeft{
  from{
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
.slideInLeft{
  -webkit-animation-name:slideInLeft;
  animation-name:slideInLeft
}
@-webkit-keyframes slideInRight{
  from{
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
@keyframes slideInRight{
  from{
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
.slideInRight{
  -webkit-animation-name:slideInRight;
  animation-name:slideInRight
}
@-webkit-keyframes slideInUp{
  from{
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
@keyframes slideInUp{
  from{
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0);
      visibility:visible
  }
  to{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
}
.slideInUp{
  -webkit-animation-name:slideInUp;
  animation-name:slideInUp
}
@-webkit-keyframes slideOutDown{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
}
@keyframes slideOutDown{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(0,100%,0);
      transform:translate3d(0,100%,0)
  }
}
.slideOutDown{
  -webkit-animation-name:slideOutDown;
  animation-name:slideOutDown
}
@-webkit-keyframes slideOutLeft{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
}
@keyframes slideOutLeft{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(-100%,0,0);
      transform:translate3d(-100%,0,0)
  }
}
.slideOutLeft{
  -webkit-animation-name:slideOutLeft;
  animation-name:slideOutLeft
}
@-webkit-keyframes slideOutRight{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
}
@keyframes slideOutRight{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(100%,0,0);
      transform:translate3d(100%,0,0)
  }
}
.slideOutRight{
  -webkit-animation-name:slideOutRight;
  animation-name:slideOutRight
}
@-webkit-keyframes slideOutUp{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
}
@keyframes slideOutUp{
  from{
      -webkit-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  to{
      visibility:hidden;
      -webkit-transform:translate3d(0,-100%,0);
      transform:translate3d(0,-100%,0)
  }
}
.slideOutUp{
  -webkit-animation-name:slideOutUp;
  animation-name:slideOutUp
}
@media print{
  html{
      line-height:1.15;
      -webkit-text-size-adjust:100%
  }
  body{
      margin:0
  }
  main{
      display:block
  }
  h1{
      font-size:2em;
      margin:.67em 0
  }
  hr{
      -webkit-box-sizing:content-box;
      box-sizing:content-box;
      height:0;
      overflow:visible
  }
  pre{
      font-family:monospace,monospace;
      font-size:1em
  }
  a{
      background-color:transparent
  }
  abbr[title]{
      border-bottom:none;
      text-decoration:underline;
      -webkit-text-decoration:underline dotted;
      text-decoration:underline dotted
  }
  b,strong{
      font-weight:bolder
  }
  code,kbd,samp{
      font-family:monospace,monospace;
      font-size:1em
  }
  small{
      font-size:80%
  }
  sub,sup{
      font-size:75%;
      line-height:0;
      position:relative;
      vertical-align:baseline
  }
  sub{
      bottom:-.25em
  }
  sup{
      top:-.5em
  }
  img{
      border-style:none
  }
  button,input,optgroup,select,textarea{
      font-family:inherit;
      font-size:100%;
      line-height:1.15;
      margin:0
  }
  button,input{
      overflow:visible
  }
  button,select{
      text-transform:none
  }
  [type=button],[type=reset],[type=submit],button{
      -webkit-appearance:button
  }
  [type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{
      border-style:none;
      padding:0
  }
  [type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{
      outline:1px dotted ButtonText
  }
  fieldset{
      padding:.35em .75em .625em
  }
  legend{
      -webkit-box-sizing:border-box;
      box-sizing:border-box;
      color:inherit;
      display:table;
      max-width:100%;
      padding:0;
      white-space:normal
  }
  progress{
      vertical-align:baseline
  }
  textarea{
      overflow:auto
  }
  [type=checkbox],[type=radio]{
      -webkit-box-sizing:border-box;
      box-sizing:border-box;
      padding:0
  }
  [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
      height:auto
  }
  [type=search]{
      -webkit-appearance:textfield;
      outline-offset:-2px
  }
  [type=search]::-webkit-search-decoration{
      -webkit-appearance:none
  }
  ::-webkit-file-upload-button{
      -webkit-appearance:button;
      font:inherit
  }
  details{
      display:block
  }
  summary{
      display:list-item
  }
  template{
      display:none
  }
  [hidden]{
      display:none
  }
  *{
      -webkit-box-sizing:border-box;
      box-sizing:border-box
  }
  *,:after,:before,blockquote:first-letter,blockquote:first-line,div:first-letter,div:first-line,li:first-letter,li:first-line,p:first-letter,p:first-line{
      background:0 0!important;
      -webkit-box-shadow:none!important;
      box-shadow:none!important;
      text-shadow:none!important
  }
  html{
      font-size:18px;
      margin:0;
      padding:0
  }
  body{
      -moz-osx-font-smoothing:grayscale;
      -webkit-font-smoothing:antialiased;
      background:#fff!important;
      color:#000!important;
      font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size:1rem;
      line-height:1.5;
      margin:0 auto;
      text-rendering:optimizeLegibility
  }
  blockquote,dl,ol,p,table,ul{
      margin-bottom:1.5rem;
      margin-top:0
  }
  ol:last-child,p:last-child,ul:last-child{
      margin-bottom:0
  }
  h1,h2,h3,h4,h5,h6{
      color:#000;
      font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
      line-height:1.2;
      margin-bottom:.75rem;
      margin-top:0
  }
  h1{
      font-size:2.5rem
  }
  h2{
      font-size:2rem
  }
  h3{
      font-size:1.75rem
  }
  h4{
      font-size:1.5rem
  }
  h5{
      font-size:1.25rem
  }
  h6{
      font-size:1rem
  }
  a,a:visited{
      color:#000;
      text-decoration:underline;
      word-wrap:break-word
  }
  table{
      border-collapse:collapse
  }
  thead{
      display:table-header-group
  }
  table,td,th{
      border-bottom:1px solid #000
  }
  td,th{
      padding:8px 16px;
      page-break-inside:avoid
  }
  code,kbd,pre{
      border:1px solid #bbb;
      font-family:Menlo,Monaco,Consolas,"Courier New",monospace;
      font-size:85%
  }
  code,kbd{
      padding:3px
  }
  pre{
      margin-bottom:1.5rem;
      padding:10px 12px
  }
  pre code,pre kbd{
      border:0
  }
  ::-webkit-input-placeholder{
      color:transparent
  }
  :-moz-placeholder{
      color:transparent
  }
  ::-moz-placeholder{
      color:transparent
  }
  :-ms-input-placeholder{
      color:transparent
  }
  blockquote{
      border:0;
      border-left:5px solid #bbb;
      margin-left:1px;
      padding:12px 1.5rem
  }
  [dir=rtl] blockquote{
      border-left:0;
      border-right:5px solid #bbb;
      margin-left:0;
      margin-right:1px
  }
  blockquote:first-child{
      margin-top:0
  }
  blockquote ol:last-child,blockquote p:last-child,blockquote ul:last-child{
      margin-bottom:0
  }
  blockquote footer{
      display:block;
      font-size:80%
  }
  img{
      border:0;
      display:block;
      max-width:100%!important;
      vertical-align:middle
  }
  hr{
      border:0;
      border-bottom:2px solid #bbb;
      height:0;
      margin:2.25rem 0;
      padding:0
  }
  dt{
      font-weight:700
  }
  dd{
      margin:0;
      margin-bottom:.75rem
  }
  abbr[title],acronym[title]{
      border:0;
      text-decoration:none
  }
  a,blockquote,code,figure,hr,li,ol,pre,table,tr,ul{
      page-break-inside:avoid
  }
  a,h2,h3,h4,p{
      orphans:3;
      widows:3
  }
  h1,h2,h3,h4,h5,h6{
      page-break-after:avoid;
      page-break-inside:avoid
  }
  h1+p,h2+p,h3+p{
      page-break-before:avoid
  }
  img{
      page-break-after:auto;
      page-break-before:auto;
      page-break-inside:avoid
  }
  pre{
      white-space:pre-wrap!important;
      word-wrap:break-word
  }
  a[href^=ftp]:after,a[href^=http]:after{
      content:" (" attr(href) ")";
      font-size:80%
  }
  a[href$='.gif']:after,a[href$='.jpeg']:after,a[href$='.jpg']:after,a[href$='.png']:after{
      display:none
  }
  abbr[title]:after,acronym[title]:after{
      content:" (" attr(title) ")"
  }
  .page-break,.page-break-before{
      page-break-before:always
  }
  .page-break-after{
      page-break-after:always
  }
  .no-print{
      display:none
  }
  a.no-reformat:after{
      content:''
  }
  abbr[title].no-reformat:after,acronym[title].no-reformat:after{
      content:''
  }
  .no-reformat a:after,.no-reformat abbr:after,.no-reformat acronym:after{
      content:''
  }
  @page{
      margin:2cm
  }
  h1,h2,h3,h4,h5,h6{
      font-family:'Helvetica Neue',Helvetica,Arial,sans-serif
  }
  body{
      font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;
      text-align:justify
  }
  h1{
      font-weight:700;
      letter-spacing:-1px;
      text-align:center
  }
  h2{
      letter-spacing:-1px
  }
  h2,h3,h4,h5{
      color:#262626
  }
  code,pre{
      border:0
  }
  blockquote,code,pre{
      background:#f8f8f9
  }
  blockquote{
      margin-left:1.5rem;
      margin-right:1.5rem
  }
  header{
      position:static
  }
  header.header-white svg{
      fill:#000!important
  }
  header .mobile-inactive{
      display:none
  }
  footer{
      margin-top:0;
      padding:30px 0 20px;
      text-align:left!important
  }
  footer .columns{
      float:none!important
  }
  footer .row{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex
  }
  footer .row>div a,footer .row>div p{
      color:#000!important;
      text-decoration:none
  }
  footer h4{
      padding-top:18px
  }
  h1,h2,h3,h4{
      letter-spacing:0
  }
  h1 span{
      font-weight:400!important
  }
  .btn{
      padding:0!important;
      color:#000!important
  }
  hr{
      display:none
  }
  .text-center{
      text-align:left
  }
  .stripe-gray{
      padding-left:.9375rem!important;
      padding-right:.9375rem!important;
      width:100%!important
  }
  .stripe-gray h2 span{
      font-weight:400
  }
  img{
      -webkit-filter:grayscale(100%);
      -moz-filter:grayscale(100%);
      -ms-filter:grayscale(100%);
      -o-filter:grayscale(100%);
      filter:grayscale(100%)
  }
  svg{
      -webkit-filter:grayscale(100%);
      -moz-filter:grayscale(100%);
      -ms-filter:grayscale(100%);
      -o-filter:grayscale(100%);
      filter:grayscale(100%)
  }
  .gap-top-medium{
      padding-top:60px!important
  }
  .gap-bottom-xsmall{
      padding-bottom:20px
  }
  .grecaptcha-badge,.grecaptcha-logo,iframe{
      display:none!important;
      visibility:hidden!important;
      opacity:0
  }
  .branding h1{
      padding-bottom:20px
  }
  .branding .brand-guides{
      padding-top:20px
  }
  .branding .logo-artworks,.branding .logo-inversions,.branding .logo-spacing,.branding .logo-versions,.branding .logo-writing{
      margin:90px auto 20px;
      padding:40px 0 60px;
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      position:relative
  }
  .branding .logo-artworks h2,.branding .logo-inversions h2,.branding .logo-spacing h2,.branding .logo-versions h2,.branding .logo-writing h2{
      position:absolute;
      top:0;
      left:0
  }
  .branding .logo-artworks .logo-holder-black,.branding .logo-inversions .logo-holder-black,.branding .logo-spacing .logo-holder-black,.branding .logo-versions .logo-holder-black,.branding .logo-writing .logo-holder-black{
      background-color:#000!important
  }
  .branding h2,.branding h3,.branding h4{
      font-size:18px
  }
  .branding .download{
      display:none
  }
  .branding svg{
      max-width:100px;
      margin:0!important
  }
  .branding img{
      top:0;
      bottom:0
  }
  .contact form{
      text-align:left;
      border-top:none
  }
  .contact form input.btn{
      display:none!important
  }
  .customers .header-background{
      display:none
  }
  .customers>.row.gap-top-small>.columns{
      width:50%
  }
  .customers>.row.gap-top-small .customer{
      height:160px;
      border:1px solid #000;
      text-align:left
  }
  .customers>.row.gap-top-small .customer img{
      display:none
  }
  .customers>.row.gap-top-small .customer .title,.customers>.row.gap-top-small .customer h3{
      font-size:18px;
      font-weight:600
  }
  .customer-story .breadcrumbs{
      display:none
  }
  .customer-story .banner{
      height:auto;
      margin-bottom:110px;
      padding-top:0
  }
  .customer-story .banner h1{
      font-size:30px
  }
  .customer-story .banner h2{
      font-size:24px
  }
  .customer-story .banner img{
      display:none
  }
  .customer-story .content h3{
      margin-bottom:30px;
      font-size:30px
  }
  .customer-story .content h4 img{
      display:none
  }
  .customer-story .content a,.customer-story .content h4,.customer-story .content p{
      font-size:18px!important
  }
  .customer-story .content blockquote{
      border:none
  }
  .customer-story .content .customer-video{
      display:none
  }
  .customer-story .content .company-details img{
      margin:0 auto
  }
  .features .main-background h1{
      padding:20px 0 10px;
      color:#000
  }
  .features .all-features{
      padding-bottom:20px
  }
  .features .all-features ul.small-block-grid-2{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      text-align:left
  }
  .features .all-features ul.small-block-grid-2 li{
      float:none
  }
  .features .all-features ul.small-block-grid-2 li a{
      text-decoration:none!important
  }
  .features .brands{
      display:none
  }
  .features .testimony{
      padding-top:40px
  }
  .features .testimony blockquote{
      padding:0;
      font-size:18px;
      font-weight:400;
      border:none
  }
  .features .fast svg{
      display:none
  }
  .features .fast h2{
      padding:0
  }
  .features .fast h3{
      margin-top:60px;
      font-size:18px;
      font-weight:600
  }
  .features .get-started{
      padding-top:80px
  }
  .features .get-started h2 span{
      font-weight:400
  }
  .features .get-started .large-centered{
      display:none
  }
  .contact.get-a-quote form{
      text-align:left;
      border-top:none
  }
  .contact.get-a-quote form .btn{
      display:none
  }
  .contact.get-a-quote .row:last-child{
      display:none
  }
  .frontpage{
      padding-top:60px
  }
  .frontpage .header-background{
      height:auto;
      min-height:auto;
      max-height:none;
      position:static;
      overflow:visible
  }
  .frontpage .header-background .top-banner{
      position:static;
      margin-top:0
  }
  .frontpage .header-background .top-banner p a.release-note{
      color:#000
  }
  .frontpage .header-background .top-banner h1 a{
      color:#000
  }
  .frontpage .brands-frontpage{
      display:none
  }
  .frontpage .highlights{
      padding-bottom:40px
  }
  .frontpage .testimony{
      padding:20px 0
  }
  .frontpage .testimony .bubble{
      width:auto;
      margin:30px 0 10px
  }
  .frontpage .testimony .bubble .quote{
      padding:0;
      font-size:14px
  }
  .frontpage .testimony .bubble .benefits-testimonial{
      margin-top:20px
  }
  .frontpage .testimony .bubble .benefits-testimonial p{
      font-size:14px;
      font-weight:400!important
  }
  .frontpage .testimony .bubble .benefits-testimonial .columns{
      padding:0
  }
  .frontpage .get-started{
      padding-left:.9375rem!important;
      padding-right:.9375rem!important;
      width:100%!important
  }
  .frontpage .get-started h2 span{
      font-weight:400
  }
  .frontpage .get-started>div{
      display:none
  }
  .knowledge-base h1{
      padding-bottom:20px
  }
  .knowledge-base h3{
      font-size:24px
  }
  .knowledge-base .answers h4 a{
      font-weight:600;
      text-decoration:none
  }
  .knowledge-base .answers>div{
      height:auto
  }
  .pricing>.gap-bottom-small{
      padding:0
  }
  .pricing>.gap-bottom-small .row:first-child>.columns:first-child{
      padding:0!important
  }
  .pricing .tabs .tab{
      display:none!important
  }
  .pricing .tabs .pricing-plans{
      width:100%;
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
      border:none
  }
  .pricing .tabs .pricing-plans .plan{
      padding:0 0 20px!important
  }
  .pricing .tabs .pricing-plans .plan.recommended{
      color:#000
  }
  .pricing .tabs .pricing-plans ul.text-left{
      margin:0
  }
  .pricing .tabs .pricing-plans h3{
      font-size:18px
  }
  .pricing .tabs .pricing-plans h3 span{
      line-height:1
  }
  .pricing .tabs .pricing-plans .price{
      font-size:18px!important
  }
  .pricing .tabs .pricing-plans .btn,.pricing .tabs .pricing-plans .selectbox{
      display:none
  }
  .pricing .tabs .pricing-plans svg{
      fill:#000
  }
  .pricing .tabs #tab-content-distribution{
      display:block
  }
  .pricing .tabs #tab-content-distribution .pricing-plans>.row{
      display:none
  }
  .pricing .purchase-options{
      padding-bottom:0!important
  }
  .pricing .purchase-options>.row{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex
  }
  .pricing .purchase-options .svg-icon{
      max-width:60px!important;
      max-height:60px!important
  }
  .pricing .purchase-options h3{
      font-size:18px;
      font-weight:600!important
  }
  .pricing .q-and-a .question-item p{
      margin-bottom:30px
  }
  .pricing .q-and-a h3 span{
      font-weight:400
  }
  .pricing .q-and-a h4{
      font-size:18px!important
  }
  .resellers h1{
      padding-bottom:20px
  }
  .resellers ul{
      clear:both
  }
  .resellers li{
      width:50%;
      text-align:left
  }
  .resellers li h3{
      font-size:16px
  }
  .resellers li a,.resellers li p{
      font-size:12px;
      line-height:1.3
  }
  .resellers h2 span{
      font-weight:400
  }
  .resellers .btn{
      display:none
  }
  .resellers .get-started{
      display:none
  }
  .team .row .row{
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex
  }
  .team h1{
      padding-bottom:20px
  }
  .team h4{
      font-size:14px
  }
  .team h4 small{
      font-size:14px;
      font-weight:400
  }
  .team img{
      max-width:120px!important;
      margin:0 auto
  }
} */
