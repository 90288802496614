@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #73AD21; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    float:left
  }
  .spinner-container{
    width:50px
  }
  .parent-loading-text{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }